import React from 'react';
import Main from './Main';

// const FRONT_BASE_URL = 'http://54.206.190.120:5000/';
// const BASE_URL = 'http://54.206.190.120:4000';

// const FRONT_BASE_URL = 'https://portal.monachildrenservices.com.au/';
// const BASE_URL = 'https://portal.monachildrenservices.com.au:4000';
// const IGNORE_REMOVE_FORM = 'Compliance Visit Form';

const FRONT_BASE_URL = process.env.REACT_APP_FRONT_BASE_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const IGNORE_REMOVE_FORM = process.env.REACT_APP_IGNORE_REMOVE_FORM;

function App() {
  return (
    <div id="main">
      <Main />
    </div>
  );
}

export default App;
export { BASE_URL, FRONT_BASE_URL, IGNORE_REMOVE_FORM };

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Form } from 'react-bootstrap';
import LeftNavbar from '../components/LeftNavbar';
import TopHeader from '../components/TopHeader';
import Multiselect from 'multiselect-react-dropdown';
import { BASE_URL } from '../components/App';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import DragDropFileEdit from '../components/DragDropFileEdit';
import { FullLoader } from '../components/Loader';
import VideoPopupfForFile from '../components/VideoPopupfForFile';
import _ from 'lodash';
import { MonaRoles } from '../constant';

const getUser_Role = localStorage.getItem(`user_role`);

let selectedUserId = '';
const LSToken = localStorage.getItem('token');
const AxiosHeaders = { headers: { Authorization: `Bearer ${LSToken}` } }

const RepoEdit = () => {
  const [url, setUrl] = React.useState('');
  const Params = useParams();
  const navigate = useNavigate();
  const [selectedFranchisee, setSelectedFranchisee] = useState(
    'Special DayCare, Sydney'
  );
  const [errors, setErrors] = useState({});
  const [category, setCategory] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [franchiseeList, setFranchiseeList] = useState();
  const [sendToAllFranchisee, setSendToAllFranchisee] = useState('all');
  const [error, setError] = useState(false);
  const [coverImage, setCoverImage] = useState({});
  const [selectedChild, setSelectedChild] = useState([]);
  const [child, setChild] = useState([]);
  const [loaderFlag, setLoaderFlag] = useState(false);
  const [generalCategory, setGeneralCategory] = useState('');
  const [fullLoaderStatus, setfullLoaderStatus] = useState(true);
  const [userCount, setUserCount] = useState(0);
  const [fullUserList, setFullUserList] = useState([]);
  const [accessToAllFranchise, setAccessToAllFranchise] = useState([]);
  const [formSettings, setFormSettings] = useState({
    assigned_role: [],
    franchisee: [],
    // assigned_users: []
  });
  const [tempData, setTempData] = useState({});

  const GetData = async () => {
    let response = await axios.get(
      `${BASE_URL}/fileRepo/fileInfo/${Params.id}`, AxiosHeaders);
    if (response) {
      setfullLoaderStatus(false);
    }

    if (response.status === 200 && response.data.status === 'success') {
      const { file } = response.data;
      copyFetchedData(file);
    }
  };
  const copyFetchedData = (data) => {
    console.log('DATA REPOSITORY:::', data);
    setData((prevState) => ({
      ...prevState,
      id: data?.id,
      createdAt: data?.createdAt,
      description: data?.description,
      title: data?.title,
      categoryId: data?.repository_files[0]?.categoryId,
      image: data?.repository_files[0]?.filesPath,
      fileName: data?.repository_files[0]?.fileName,
      franchise: data?.repository_shares[0]?.franchisee,
      accessibleToRole: data?.repository_shares[0]?.accessibleToRole,
      accessibleToAll: data?.repository_shares[0]?.accessibleToAll,
      assigned_users: data?.repository_shares[0]?.assigned_users,
      user_roles: data?.repository_shares[0]?.assigned_roles,
      assigned_childs: data?.repository_shares[0]?.assigned_childs,
      file_type: data?.repository_files[0]?.fileType,
    }));
    setTempData((prevState) => ({
      ...prevState,
      assigned_users: data?.repository_shares[0]?.assigned_users,
      user_roles: data?.repository_shares[0]?.assigned_roles,
      assigned_childs: data?.repository_shares[0]?.assigned_childs,
    }));
    setCoverImage(data?.repository_files[0].filesPath);
    let allowAccessToAllFranchisee =
      data?.repository_shares[0].franchisee.length > 0 ? false : true;
    setAccessToAllFranchise(allowAccessToAllFranchisee);
    data?.repository_shares[0].franchisee.length == 0
      ? setSendToAllFranchisee('all')
      : setSendToAllFranchisee('none');
    data?.repository_shares[0].assigned_users.length == 0
      ? setUserCount(0)
      : setUserCount(data?.repository_shares[0].assigned_users.length);
  };

  const onChange = (e) => {
    const files = data.image;
    files.length > 0 && setUrl(files);
  };
  // FUNCTION TO SAVE TRAINING DATA
  const handleDiscriptionData = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // Update API For File Repo

  const handleDataSubmit = async (event) => {
    event.preventDefault();
    setLoaderFlag(true);
    if (
      !data.image ||
      !data.description ||
      data.description == '' ||
      !data.categoryId
    ) {
      setError(true);
      return false;
    }
    let dataObj = new FormData();
    for (let [key, value] of Object.entries(data)) {
      dataObj.append(key, value);
    }

    saveDataToServer(dataObj);
  };

  const saveDataToServer = async () => {
    setLoaderFlag(true);
    const token = localStorage.getItem('token');
    let response = await axios.put(`${BASE_URL}/fileRepo`, data, AxiosHeaders);
    if (response.status === 200 && response.data.status === 'success') {
      if (typeof data.image === 'string') {
        response = await axios.patch(`${BASE_URL}/fileRepo/updateFilePath/${Params.id}`, { filesPath: data.image }, AxiosHeaders);
        if (response.status === 201 && response.data.status === 'success') {
          navigate(`/file-repository-List-me/${data.categoryId}`);
        }
      } else if (typeof data.image === 'object') {
        let dataObj = new FormData();
        dataObj.append('image', data.image);
        dataObj.append('id', Params.id);
        dataObj.append('title', data.title);
        dataObj.append('description', data.description);
        response = await axios.post(`${BASE_URL}/fileRepo/data/saveImageData`, dataObj, AxiosHeaders);
        if (response.status === 200 && response.data.status === 'success') {
          setLoaderFlag(false);
          navigate(`/file-repository-List-me/${data.categoryId}`);
        }
      }
      setLoaderFlag(false);
      navigate(`/file-repository-List-me/${data.categoryId}`);
    } else {
      setLoaderFlag(false);
    }
  };

  const childList = async () => {
    const token = localStorage.getItem('token');
    let response = await axios.get(`${BASE_URL}/enrollment/listOfChildren?childId=${JSON.stringify(data.assigned_users ? data.assigned_users : [])}`, AxiosHeaders);
    if (response.status === 200 && response.data.status === 'success') {
      let extraArr = [];
      let parents = response.data.parentData.map((item) => {
        return item.children;
      });

      parents.forEach((item) => {
        extraArr = [...item, ...extraArr];
      });

      let uniqArr = _.uniqBy(extraArr, function (e) {
        return e.id;
      });

      setChild(
        uniqArr.map((data) => ({
          id: data.id,
          name: data.fullname,
          key: `${data.fullname}`,
        }))
      );
    }
  };

  const fetchFranchiseeList = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${BASE_URL}/role/franchisee`, AxiosHeaders);
    if (response.status === 200 && response.data.status === 'success') {
      setFranchiseeList(
        response.data.franchiseeList.map((data) => ({
          id: data.id,
          cat: data.franchisee_alias,
          key: `${data.franchisee_name}, ${data.city}`,
        }))
      );
    }
  };
  const getFileCategory = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${BASE_URL}/fileRepo/files-category/`, AxiosHeaders);
    if (response.status === 200 && response.data.status === 'success') {
      const categoryList = response.data.category;
      setGeneralCategory(categoryList[0].id);
      setCategory([
        ...categoryList.map((data) => ({
          id: data.id,
          value: data.category_name,
          label: data.category_name,
        })),
      ]);
    }
  };
  const getUser = async (param) => {
    // var myHeaders = new Headers();
    // let myHeaders = {
    //   Authorization: 'Bearer ' + localStorage.getItem('token'),
    // };

    // var request = {
    //   headers: myHeaders,
    // };

    let franchiseeArr = data.franchise.length == 0 ? 'all' : data.franchise;
    let userIdd = localStorage.getItem('user_id');
    let response = await axios.post(`${BASE_URL}/auth/users/franchisee-list`, { userId: userIdd || [], }, AxiosHeaders);
    if (response.status === 200) {
      let userList = response.data.users;

      let formattedUserData = userList.map((d) => ({
        id: d.id,
        fullname: d.fullname,
        email: d.email,
        namemail: `${d.fullname} (${d.email})`,
        franchisee_id: d?.franchisee_id,
      }));
      setFullUserList(formattedUserData);
      if (typeof param === 'undefined') {
        userList = userList?.filter((item) =>
          franchiseeArr?.includes(item?.franchisee_id + '')
        );
      }
      setUser(formattedUserData);
    }
  };

  const setField = async (field, value) => {
    setData({ ...data, image: field[0] });
    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const setFieldd = async (field, value) => {
    setData({ ...data, categoryId: field[0] });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  function onRemoveChild(removedItem) {
    let removedchildarr = removedItem;
    removedItem = removedItem.map((item) => {
      return item.id;
    });
    setData((prevState) => ({
      ...prevState,
      assigned_childs: removedItem,
    }));
    setSelectedChild(removedchildarr);
  }

  const isAllRolesChecked = () => {
    let bool = false;
    if (getUser_Role == 'franchisor_admin') {
      bool = data.user_roles.length === 4;
    } else if (getUser_Role == 'franchisee_admin') {
      bool = data.user_roles.length === 3;
    } else if (getUser_Role == 'coordinator') {
      bool = data.user_roles.length === 2;
    } else if (getUser_Role == 'educator') {
      bool = ['guardian'].every((item) => data?.user_roles?.includes(item));
    }
    return bool;
  };

  const getUserOptions = () => {
    if (accessToAllFranchise) {
      return fullUserList;
    }

    let populatedData = fullUserList?.filter((item) => {
      if (data?.franchise?.includes(item?.franchisee_id + '')) {
        return item;
      }
    });

    return populatedData;
  };

  const getPopulatedUserValues = () => {
    if (fullUserList?.length === 0) return [];

    let populatedUsers = fullUserList;
    populatedUsers = populatedUsers?.filter((item) =>
      data?.franchise?.includes(item?.franchisee_id + '')
    );

    populatedUsers = populatedUsers?.filter((item) =>
      tempData?.assigned_users?.includes(item?.id + '')
    );

    return populatedUsers;
  };

  useEffect(() => {
    GetData();
    getFileCategory();
    getUser();
    fetchFranchiseeList();
    // childList()
  }, [setSelectedUser]);

  useEffect(() => {
    // childList();
    getUser();
  }, [data.franchise]);

  useEffect(() => {
    childList();
    // getUser();
  }, [userCount]);

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <FullLoader loading={fullLoaderStatus} />
      <div id="main">
        <section className="mainsection">
          <Container>
            <div className="admin-wrapper">
              <aside className="app-sidebar">
                <LeftNavbar />
              </aside>
              <div className="sec-column">
                <TopHeader
                  selectedFranchisee={selectedFranchisee}
                  setSelectedFranchisee={setSelectedFranchisee}
                />
                <div className="entry-container">
                  <header className="title-head">
                    <h1 className="title-lg">
                      Edit File{' '}
                      <span className="setting-ico">
                        <img src="../img/setting-ico.png" alt="" />
                      </span>
                    </h1>
                  </header>
                  <div className="form-settings-content">
                    <div className="form-settings-content">
                      <div className="modal-top">
                        <div className="modal-top-containt">
                          <Row>
                            <Form.Group>
                              <DragDropFileEdit onChange={setField} />
                              <div className="showfiles mt-3 text-center">
                                {typeof data.image === 'string' ? (
                                  <>
                                    {data.file_type === 'image/jpeg' ||
                                      data.file_type === 'image/png' ||
                                      data.file_type === 'image/jpe' ? (
                                      <img
                                        src={data.image}
                                        alt="smkdjh"
                                        style={{
                                          maxWidth: '150px',
                                          height: 'auto',
                                          borderRadius: '10px',
                                        }}
                                      />
                                    ) : data.file_type === 'application/pdf' ||
                                      data.file_type === 'text/html' ||
                                      data.file_type === 'text/pdf' ||
                                      data.file_type === 'text/csv' ||
                                      data.file_type ===
                                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                      data.file_type ===
                                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                                      <>
                                        <span className="user-pic-tow">
                                          <a href={data.image} download>
                                            <img
                                              src="../img/abstract-ico.png"
                                              className="me-2"
                                              alt=""
                                            />
                                          </a>
                                        </span>
                                        <span className="user-name">
                                          {data.fileName}.
                                          {data.file_type.replace(
                                            'application/',
                                            ''
                                          )}
                                        </span>
                                      </>
                                    ) : data.file_type === 'video/mp4' ? (
                                      <>
                                        <div
                                          style={{ display: 'inline-table' }}
                                        >
                                          <VideoPopupfForFile
                                            data={data.image}
                                          />
                                          {/* <FileRepoVideo
                                                                                                data={data.image}
                                                                                            /> */}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <span className="user-pic-tow">
                                          <a href={data.image} download>
                                            <img
                                              src="../img/abstract-ico.png"
                                              className="me-2"
                                              alt=""
                                            />
                                          </a>
                                        </span>
                                        <span className="user-name">
                                          {data.fileName}.{data.file_type}
                                        </span>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {error && !data.image && (
                                <span className="error">
                                  {' '}
                                  File is required!
                                </span>
                              )}
                              <p className="error">{errors.setting_files}</p>

                              {errors && errors.setField && (
                                <span className="error mt-2">
                                  {errors.coverImage}
                                </span>
                              )}
                            </Form.Group>
                          </Row>

                          <div className="setting-heading">
                            <h2>Settings</h2>
                          </div>
                        </div>
                        <hr></hr>
                      </div>
                      <div className="modal-bottom">
                        <Row>
                          <Col lg={12}>
                            <div className="metadescription">
                              <Form.Group className="mb-3">
                                <Form.Label>Meta Description*</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={2}
                                  name="description"
                                  value={data?.description}
                                  onChange={handleDiscriptionData}
                                />
                              </Form.Group>
                              {error && !data.description && (
                                <span className="error">
                                  {' '}
                                  Description is required!
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col lg={12}>
                            {getUser_Role === 'guardian' ? (
                              ''
                            ) : (
                              <>
                                <Form.Group>
                                  <Form.Label>File Category*</Form.Label>
                                  {getUser_Role === 'guardian' ? (
                                    <>
                                      <Form.Select
                                        name="file_category"
                                        onChange={(e) => {
                                          setField(
                                            e.target.name,
                                            e.target.value
                                          );
                                        }}
                                        disabled={true}
                                      >
                                        {/* <option value="8">Select</option> */}
                                        <option
                                          value={generalCategory}
                                          selected={true}
                                        >
                                          General
                                        </option>
                                      </Form.Select>
                                    </>
                                  ) : (
                                    <>
                                      <Form.Select
                                        name="file_category"
                                        onChange={(e) => {
                                          setFieldd(e.target.value);
                                        }}
                                        value={data?.categoryId}
                                      >
                                        <option value="">Select</option>
                                        {category?.map((item) => {
                                          return (
                                            <option value={item.id}>
                                              {item.value}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </>
                                  )}

                                  {error && !data?.categoryId && (
                                    <span className="error">
                                      {' '}
                                      File Category is required!
                                    </span>
                                  )}
                                  {errors && errors?.categoryId && (
                                    <span className="error">
                                      {errors?.categoryId}
                                    </span>
                                  )}
                                </Form.Group>
                              </>
                            )}
                          </Col>
                        </Row>
                        {getUser_Role === 'guardian' ? (
                          <></>
                        ) : (
                          <>
                            {getUser_Role !== 'franchisor_admin' ? (
                              <></>
                            ) : (
                              <Row className="mt-4">
                                <Col lg={3} md={6}>
                                  <Form.Group>
                                    <Form.Label>
                                      Give access to all franchises
                                    </Form.Label>
                                    <div className="new-form-radio d-block">
                                      <div className="new-form-radio-box">
                                        <label htmlFor="all">
                                          <input
                                            type="radio"
                                            checked={
                                              sendToAllFranchisee === 'all'
                                            }
                                            name="send_to_all_franchisee"
                                            id="all"
                                            onChange={() => {
                                              setFormSettings((prevState) => ({
                                                ...prevState,
                                                assigned_franchisee: [],
                                              }));
                                              setSendToAllFranchisee('all');
                                              // getUser('all');
                                              setUser(fullUserList);
                                              setAccessToAllFranchise(true);
                                              childList();
                                            }}
                                            disabled={
                                              getUser_Role !==
                                              'franchisor_admin'
                                            }
                                          />
                                          <span className="radio-round"></span>
                                          <p>Yes</p>
                                        </label>
                                      </div>
                                      <div className="new-form-radio-box m-0 mt-3">
                                        <label htmlFor="none">
                                          <input
                                            type="radio"
                                            name="send_to_all_franchisee"
                                            checked={
                                              sendToAllFranchisee === 'none'
                                            }
                                            id="none"
                                            onChange={() => {
                                              setFormSettings((prevState) => ({
                                                ...prevState,
                                                assigned_franchisee: ['none'],
                                              }));
                                              setSendToAllFranchisee('none');
                                              setAccessToAllFranchise(false);
                                            }}
                                            disabled={
                                              getUser_Role !==
                                              'franchisor_admin'
                                            }
                                          />
                                          <span className="radio-round"></span>
                                          <p>No</p>
                                        </label>
                                      </div>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg={9} md={12}>
                                  <Form.Group>
                                    <Form.Label>Select franchise(s)</Form.Label>
                                    <div className="select-with-plus">
                                      <Multiselect
                                        isClearable={false}
                                        disable={
                                          sendToAllFranchisee === 'all' ||
                                          getUser_Role !== 'franchisor_admin'
                                        }
                                        placeholder={'Select'}
                                        displayValue="key"
                                        className="multiselect-box default-arrow-select"
                                        onRemove={(item) => {
                                          let idList = item?.map(
                                            (key) => key?.id + ''
                                          );

                                          setData((prevState) => ({
                                            ...prevState,
                                            franchise: idList,
                                            franchisee: idList,
                                          }));
                                          setSelectedUser([]);
                                          setSelectedChild([]);
                                        }}
                                        selectedValues={
                                          franchiseeList &&
                                          franchiseeList.filter((c) =>
                                            data.franchise?.includes(c.id + '')
                                          )
                                        }
                                        onSelect={(item) => {
                                          let idList = item?.map(
                                            (key) => key?.id + ''
                                          );

                                          setData((prevState) => ({
                                            ...prevState,
                                            franchise: idList,
                                            franchisee: idList,
                                            // assigned_childs: [],
                                            // assigned_users: [],
                                          }));

                                          setSelectedUser([]);
                                          setSelectedChild([]);
                                        }}
                                        options={franchiseeList}
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                              </Row>
                            )}
                            {sendToAllFranchisee === 'none' &&
                              data.franchise.length < 1 ? (
                              ''
                            ) : (
                              <Row className="mt-4">
                                <Col lg={3} md={6}>
                                  <Form.Group>
                                    <Form.Label>Accessible to</Form.Label>
                                    <div className="new-form-radio d-block">
                                      <div className="new-form-radio-box">
                                        <label htmlFor="yes">
                                          <input
                                            type="radio"
                                            value={1}
                                            name="accessibleToRole"
                                            id="yes"
                                            onChange={(event) => {
                                              setData((prevState) => ({
                                                ...prevState,
                                                accessibleToRole: 1,
                                              }));
                                            }}
                                            checked={
                                              data.accessibleToRole === 1
                                            }
                                          />
                                          <span className="radio-round"></span>
                                          <p>User Roles</p>
                                        </label>
                                      </div>
                                      <div className="new-form-radio-box m-0 mt-3">
                                        <label htmlFor="no">
                                          <input
                                            type="radio"
                                            value={0}
                                            name="accessibleToRole"
                                            id="no"
                                            onChange={(event) => {
                                              setData((prevState) => ({
                                                ...prevState,
                                                accessibleToRole: 0,
                                              }));
                                            }}
                                            checked={
                                              data.accessibleToRole === 0
                                            }
                                          />
                                          <span className="radio-round"></span>
                                          <p>Specific Users</p>
                                        </label>
                                      </div>
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg={9} md={12}>
                                  {data.accessibleToRole === 1 ? (
                                    <Form.Group>
                                      <Form.Label>Select User Roles</Form.Label>
                                      <div className="modal-two-check user-roles-box">
                                        {['franchisor_admin'].includes(
                                          getUser_Role
                                        ) ? (
                                          <label className="container">
                                            Franchisee Admin
                                            <input
                                              type="checkbox"
                                              name="shared_role"
                                              id="franchisee_admin"
                                              checked={data?.user_roles
                                                ?.toString()
                                                .includes(MonaRoles.franchiseeAdmin)}
                                              onChange={() => {
                                                if (data.user_roles?.includes(MonaRoles.franchiseeAdmin)) {
                                                  let Data = data.user_roles.filter((t) => t !== MonaRoles.franchiseeAdmin);
                                                  setData((prevState) => ({
                                                    ...prevState,
                                                    user_roles: [...Data],
                                                  }));
                                                }
                                                if (!data.user_roles?.includes(MonaRoles.franchiseeAdmin))
                                                  setData((prevState) => ({
                                                    ...prevState,
                                                    user_roles: [
                                                      ...data.user_roles,
                                                      MonaRoles.franchiseeAdmin,
                                                    ],
                                                  }));
                                              }}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        ) : null}
                                        {[MonaRoles.franchisorAdmin, MonaRoles.franchiseeAdmin,].includes(getUser_Role) ? (
                                          <label className="container">
                                            Coordinator
                                            <input
                                              type="checkbox"
                                              name="shared_role"
                                              id="coordinator"
                                              checked={data?.user_roles
                                                ?.toString()
                                                .includes('coordinator')}
                                              onChange={() => {
                                                if (data.user_roles?.includes('coordinator')) {
                                                  let Data =
                                                    data.user_roles.filter(
                                                      (t) => t !== 'coordinator'
                                                    );
                                                  setData((prevState) => ({
                                                    ...prevState,
                                                    user_roles: [...Data],
                                                  }));
                                                }
                                                if (!data.user_roles?.includes('coordinator'))
                                                  setData((prevState) => ({
                                                    ...prevState,
                                                    user_roles: [
                                                      ...data.user_roles,
                                                      'coordinator',
                                                    ],
                                                  }));
                                              }}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        ) : null}
                                        {[
                                          'franchisor_admin',
                                          'franchisee_admin',
                                          'coordinator',
                                        ].includes(getUser_Role) ? (
                                          <label className="container">
                                            Educator
                                            <input
                                              type="checkbox"
                                              name="shared_role"
                                              id="educator"
                                              checked={data?.user_roles
                                                ?.toString()
                                                .includes('educator')}
                                              onChange={() => {
                                                if (
                                                  data.user_roles?.includes(
                                                    'educator'
                                                  )
                                                ) {
                                                  let Data =
                                                    data.user_roles.filter(
                                                      (t) => t !== 'educator'
                                                    );
                                                  setData((prevState) => ({
                                                    ...prevState,
                                                    user_roles: [...Data],
                                                  }));
                                                }
                                                if (
                                                  !data.user_roles?.includes(
                                                    'educator'
                                                  )
                                                )
                                                  setData((prevState) => ({
                                                    ...prevState,
                                                    user_roles: [
                                                      ...data.user_roles,
                                                      'educator',
                                                    ],
                                                  }));
                                              }}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        ) : null}
                                        {!['guardian'].includes(
                                          getUser_Role
                                        ) ? (
                                          <label className="container">
                                            Guardian
                                            <input
                                              type="checkbox"
                                              name="shared_role"
                                              id="guardian"
                                              checked={data?.user_roles.includes(
                                                'guardian'
                                              )}
                                              onChange={() => {
                                                if (
                                                  data.user_roles?.includes(
                                                    'guardian'
                                                  )
                                                ) {
                                                  let Data =
                                                    data.user_roles.filter(
                                                      (t) => t !== 'guardian'
                                                    );
                                                  setData((prevState) => ({
                                                    ...prevState,
                                                    user_roles: [...Data],
                                                  }));
                                                }
                                                if (
                                                  !data.user_roles?.includes(
                                                    'guardian'
                                                  )
                                                )
                                                  setData((prevState) => ({
                                                    ...prevState,
                                                    user_roles: [
                                                      ...data.user_roles,
                                                      'guardian',
                                                    ],
                                                  }));
                                              }}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        ) : null}
                                        {!['educator', 'guardian'].includes(
                                          getUser_Role
                                        ) ? (
                                          <label className="container">
                                            All Roles
                                            {/* <input
                                                                                    type="checkbox"
                                                                                    name="shared_role"
                                                                                    id="all_roles"
                                                                                    checked={isAllRolesChecked()}
                                                                                    onChange={() => {
                                                                                        if (data.user_roles?.includes("coordinator")
                                                                                            && data.user_roles.includes("educator")
                                                                                            && data.user_roles.includes("franchisee_admin")
                                                                                            && data.user_roles.includes("guardian")) {
                                                                                            setData(prevState => ({
                                                                                                ...prevState,
                                                                                                user_roles: [],
                                                                                            }));
                                                                                        }
                                                                                        if (!data.user_roles?.includes("coordinator")
                                                                                            && !data.user_roles.includes("educator")
                                                                                            && !data.user_roles.includes("franchisee_admin")
                                                                                            && !data.user_roles.includes("guardian")
                                                                                        )
                                                                                            setData(prevState => ({
                                                                                                ...prevState,
                                                                                                user_roles: ["franchisee_admin", "coordinator", "educator", "guardian"]
                                                                                            })
                                                                                            )
                                                                                    }} /> */}
                                            <input
                                              type="checkbox"
                                              name="shared_role"
                                              id="all_roles"
                                              checked={isAllRolesChecked()}
                                              onChange={() => {
                                                if (
                                                  getUser_Role ==
                                                  'franchisor_admin'
                                                ) {
                                                  if (
                                                    data.user_roles?.includes(
                                                      'coordinator'
                                                    ) &&
                                                    data.user_roles.includes(
                                                      'educator'
                                                    ) &&
                                                    data.user_roles.includes(
                                                      'guardian'
                                                    ) &&
                                                    data.user_roles.includes(
                                                      'franchisee_admin'
                                                    )
                                                  ) {
                                                    setData((prevState) => ({
                                                      ...prevState,
                                                      user_roles: [],
                                                    }));
                                                  } else {
                                                    setData((prevState) => ({
                                                      ...prevState,
                                                      user_roles: [
                                                        'coordinator',
                                                        'educator',
                                                        'guardian',
                                                        'franchisee_admin',
                                                      ],
                                                    }));
                                                  }
                                                }

                                                if (
                                                  getUser_Role ==
                                                  'franchisee_admin'
                                                ) {
                                                  if (
                                                    data.user_roles.includes(
                                                      'coordinator'
                                                    ) &&
                                                    data.user_roles.includes(
                                                      'educator'
                                                    ) &&
                                                    data.user_roles.includes(
                                                      'guardian'
                                                    )
                                                  ) {
                                                    setData((prevState) => ({
                                                      ...prevState,
                                                      user_roles: [],
                                                    }));
                                                  } else {
                                                    setData((prevState) => ({
                                                      ...prevState,
                                                      user_roles: [
                                                        'coordinator',
                                                        'educator',
                                                        'guardian',
                                                      ],
                                                    }));
                                                  }
                                                }

                                                if (
                                                  getUser_Role == 'coordinator'
                                                ) {
                                                  if (
                                                    data.user_roles.includes(
                                                      'educator'
                                                    ) &&
                                                    data.user_roles.includes(
                                                      'guardian'
                                                    )
                                                  ) {
                                                    setData((prevState) => ({
                                                      ...prevState,
                                                      user_roles: [],
                                                    }));
                                                  } else {
                                                    setData((prevState) => ({
                                                      ...prevState,
                                                      user_roles: [
                                                        'educator',
                                                        'guardian',
                                                      ],
                                                    }));
                                                  }
                                                }
                                              }}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        ) : null}
                                      </div>
                                    </Form.Group>
                                  ) : null}
                                  {data.accessibleToRole === 0 ? (
                                    <>
                                      <Form.Group>
                                        <Form.Label>Select User</Form.Label>
                                        <div className="select-with-plus">
                                          <Multiselect
                                            // disable={sendToAllFranchisee === 'all'}
                                            displayValue="namemail"
                                            className="multiselect-box default-arrow-select"
                                            selectedValues={getPopulatedUserValues()}
                                            // onRemove={onRemoveUser}
                                            onRemove={function noRefCheck(
                                              data
                                            ) {
                                              setData((prevState) => ({
                                                ...prevState,
                                                assigned_users: [
                                                  ...data.map(
                                                    (data) => data.id + ''
                                                  ),
                                                ],
                                              }));
                                              setUserCount(userCount - 1);
                                              childList();
                                            }}
                                            value={getPopulatedUserValues()}
                                            onSelect={(selectedOptions) => {
                                              console.log(
                                                'Selected Options:::',
                                                selectedOptions
                                              );
                                              setData((prevState) => ({
                                                ...prevState,
                                                assigned_users: [
                                                  ...selectedOptions.map(
                                                    (option) => option.id + ''
                                                  ),
                                                ],
                                              }));
                                              setUserCount(userCount + 1);
                                            }}
                                            options={getUserOptions()}
                                          />
                                        </div>
                                        <p className="error">
                                          {errors.franchisee}
                                        </p>
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label>Select Child</Form.Label>
                                        <div className="select-with-plus">
                                          <Multiselect
                                            // disable={sendToAllFranchisee === 'all'}
                                            placeholder={'Select'}
                                            displayValue="name"
                                            className="multiselect-box default-arrow-select"
                                            // onRemove={onRemoveChild}
                                            onRemove={function noRefCheck(
                                              data
                                            ) {
                                              setData((prevState) => ({
                                                ...prevState,
                                                assigned_childs: [
                                                  ...data.map(
                                                    (data) => data.id + ''
                                                  ),
                                                ],
                                              }));
                                            }}
                                            selectedValues={
                                              child &&
                                              child.filter((c) =>
                                                data.assigned_childs?.includes(
                                                  c.id + ''
                                                )
                                              )
                                            }
                                            value={
                                              child &&
                                              child.filter((c) =>
                                                data.assigned_childs?.includes(
                                                  c.id + ''
                                                )
                                              )
                                            }
                                            onSelect={(selectedOptions) => {
                                              setData((prevState) => ({
                                                ...prevState,
                                                assigned_childs: [
                                                  ...selectedOptions.map(
                                                    (option) => option.id + ''
                                                  ),
                                                ],
                                              }));
                                            }}
                                            options={child}
                                          />
                                        </div>
                                      </Form.Group>
                                    </>
                                  ) : null}
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                        <Row>
                          <div className="d-flex justify-content-center my-5">
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Button
                                variant="link btn btn-light btn-md m-2"
                                style={{ backgroundColor: '#efefef' }}
                                onClick={() => navigate(-1)}
                              >
                                Cancel
                              </Button>
                              <Button type="submit" onClick={handleDataSubmit}>
                                {loaderFlag === true ? (
                                  <>
                                    <img
                                      style={{ width: '24px' }}
                                      src={'/img/mini_loader1.gif'}
                                      alt=""
                                    />
                                    Updating...
                                  </>
                                ) : (
                                  'Save Details'
                                )}
                              </Button>
                            </Form.Group>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};
export default RepoEdit;

import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { BASE_URL } from "../../components/App";
import axios from 'axios';
import { useParams } from 'react-router-dom';

let nextstep = 6;
let step = 5;

const ChildEnrollment5 = ({ nextStep, prevStep }) => {
  let { childId: paramsChildId, parentId: paramsParentId } = useParams();

  const [childDailyRoutineData, setChildDailyRoutineData] = useState({
    sleep_time: "",
    bottle_time: "",
    toileting: "",
    routines: "",
    likes_dislikes: "",
    comforter: "",
    religion: "",
    dietary_requirement: "",
    allergy: "",
    comment: "",
    log: []
  });
  const [agreement, setAgreement] = useState({
    photo_taken: true,
    dress_child: true,
    remind_child: true,
    provide_child: true,
    give_permission_for_sunscreen: true,
    assist_child: true,
    log: []
  });

  // ERROR HANDLING STATE
  const [childDailyRoutineError, setChildDailyRoutineError] = useState(null);
  const [formStepData, setFormStepData] = useState(null);
  const [dailyRoutineId, setDailyRoutineId] = useState(null);
  const [loader, setLoader] = useState(false);
  const LSToken = localStorage.getItem('token');
  const AxiosHeaders = { headers: { Authorization: `Bearer ${LSToken}` } }

  const handleDailyRoutineData = (event) => {
    const { name, value } = event.target;
    setChildDailyRoutineData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // UPDATING FORM FIVE DATA
  const updateFormFiveData = async () => {
    setLoader(true);
    try {
      let childId = localStorage.getItem('enrolled_child_id');
      let response = await axios.patch(`${BASE_URL}/enrollment/daily-routine/${dailyRoutineId}`, { ...childDailyRoutineData }, AxiosHeaders);

      if(response.status === 201 && response.data.status === "success") {
        response = await axios.patch(`${BASE_URL}/enrollment/child/${childId}`, agreement, AxiosHeaders);

        if(response.status === 201 && response.data.status === "success") {
          let changeCount = localStorage.getItem('change_count');

          if(childDailyRoutineData.log.length > 0)
            changeCount++;

          if(agreement.log.length > 0)
            changeCount++;
          
          localStorage.setItem('change_count', changeCount);
          setLoader(true);
          nextStep();
        }
      }

    } catch(error) {
      console.log('ERROR: UNABLE TO UPDATE FORM FIVE!');
    }
  };

  // SAVING FORM FIVE DATA
  const saveFormFiveData = async () => {
    setLoader(true);
    try {
      let childId = localStorage.getItem('enrolled_child_id');
      let response = await axios.post(`${BASE_URL}/enrollment/daily-routine`, { ...childDailyRoutineData, childId }, AxiosHeaders)

      console.log('DAILY ROUTINE RESPONSE:', response);
      if(response.status === 201 && response.data.status === "success") {
        response = await axios.patch(`${BASE_URL}/enrollment/child/${childId}`, agreement, AxiosHeaders);

        console.log('CHILD UPDATION RESPONSE:', response);
        if(response.status === 201 && response.data.status === "success") {
          
          // UPDATING THE STEP VALUE INSIDE CHILD TABLE
          response = await axios.patch(`${BASE_URL}/enrollment/child/${childId}`, {form_step: nextstep}, AxiosHeaders);

          if(response.status === 201 && response.data.status === "success") {
            setLoader(false);
            nextStep();
          }
        }
      }
    } catch (error) {
      console.log('ERROR: FORM FIVE =>', error);
    }
  };

  const submitFormData = (e) => {
    e.preventDefault();
    if(formStepData >= step) {
      updateFormFiveData();
    } else {
      saveFormFiveData();
    }
  };

  const fetchAndPopulateDailyRoutineState = async () => {
    const response = await axios.get(`${BASE_URL}/enrollment/daily-routine/${dailyRoutineId}`, AxiosHeaders);

    if(response.status === 200 && response.data.status === "success") {
      let { dailyRoutine } = response.data;
      setChildDailyRoutineData(prevState => ({
        ...prevState,
        sleep_time: dailyRoutine.sleep_time,
        bottle_time: dailyRoutine.bottle_time,
        toileting: dailyRoutine.toileting,
        routines: dailyRoutine.routines,
        likes_dislikes: dailyRoutine.likes_dislikes,
        comforter: dailyRoutine.comforter,
        religion: dailyRoutine.religion,
        dietary_requirement: dailyRoutine.dietary_requirement,
        allergy: dailyRoutine.allergy,
        comment: dailyRoutine.comment,
      }));
    }
  };

  const fetchChildDataAndPopulate = async () => {
    let enrolledChildId = localStorage.getItem('enrolled_child_id');

    let response = await axios.get(`${BASE_URL}/enrollment/child/${enrolledChildId}?parentId=${paramsParentId}`, AxiosHeaders);

    if(response.status === 200 && response.data.status === 'success') {
      let { child } = response.data;
      // localStorage.setItem('enrolled_parent_id', child.parents[0].id);
      console.log('CHILD DATA:', child);

      if(child.form_step > step) {
        // POPULATING AGREEMENT STATE WITH DATA
        setAgreement(prevState => ({
          ...prevState,
          photo_taken: child.photo_taken,
          dress_child: child.dress_child,
          remind_child: child.remind_child,
          provide_child: child.provide_child,
          give_permission_for_sunscreen: child.give_permission_for_sunscreen,
          assist_child: child.assist_child
        }));

        setDailyRoutineId(child.daily_routine);
        setFormStepData(child.form_step);
      } 

    }
  };

  useEffect(() => {
    fetchAndPopulateDailyRoutineState();
  }, [dailyRoutineId !== null]);

  useEffect(() => {
    console.log('FETCHING CHILD DATA AND POPULATE!');
    window.scrollTo(0, 0);
    fetchChildDataAndPopulate();
  }, [localStorage.getItem('enrolled_child_id') !== null]);

  // childDailyRoutineData && console.log('DAILY ROUTINE:', childDailyRoutineData);
  agreement && console.log('AGREEMENT:', agreement);
  return (
    <>
      <div className="enrollment-form-sec error-sec mt-5">
        <Form onSubmit={submitFormData}>
          <div className="enrollment-form-column">
            <h2 className="title-xs mb-4">Photo</h2>

            <div className="grayback">
              <div className="single-col">
                <p>My child/children having their photo taken, while under care for their educational profiles</p>
                <Form.Group className="ms-auto">
                  <div className="btn-radio inline-col mb-0">
                    <Form.Check 
                      type="radio" 
                      name="photo" 
                      id="yesp" 
                      checked={agreement?.photo_taken === true}
                      defaultChecked
                      label="Yes"
                      onChange={() => {
                        setAgreement(prevState => ({
                          ...prevState,
                          photo_taken: true
                        }));

                        if(!agreement.log.includes("photo_taken")) {
                          setAgreement(prevState => ({
                            ...prevState,
                            log: [...agreement.log, "photo_taken"]
                          }));
                        }
                      }} />
                    <Form.Check 
                      type="radio" 
                      name="photo" 
                      id="nop" 
                      label="No"
                      checked={agreement?.photo_taken === false}
                      onChange={() => {
                        setAgreement(prevState => ({
                          ...prevState,
                          photo_taken: false
                         }));

                         if(!agreement.log.includes("photo_taken")) {
                          setAgreement(prevState => ({
                            ...prevState,
                            log: [...agreement.log, "photo_taken"]
                          }));
                        }
                      }} />
                  </div>
                </Form.Group>
              </div>
            </div>

            <h2 className="title-xs mt-4 mb-4">Getting to know you, your child and their daily routine</h2>

            <div className="grayback">
              <p>To help us deliver the best possible care to your child, please provide us with following information if applicable:</p>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Sleep Time</Form.Label>
                    <Form.Control 
                      style={{ resize: "none" }} 
                      type="text"
                      as="textarea"
                      rows={5}
                      name="sleep_time"
                      value={childDailyRoutineData?.sleep_time || ""}
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}
                      
                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("sleep_time")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "sleep_time"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Bottle Time/Breast Feeding Arrangements</Form.Label>
                    <Form.Control 
                      style={{ resize: "none" }} 
                      type="text"
                      as="textarea"
                      rows={5}
                      value={childDailyRoutineData?.bottle_time || ""}
                      name="bottle_time"
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}

                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("bottle_time")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "bottle_time"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Toileting</Form.Label>
                    <Form.Control 
                      style={{ resize: "none" }} 
                      type="text"
                      as="textarea"
                      rows={5}
                      name="toileting"
                      value={childDailyRoutineData?.toileting || ""}
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}

                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("toileting")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "toileting"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Routines</Form.Label>
                    <Form.Control 
                      style={{ resize: "none" }} 
                      type="text"
                      as="textarea"
                      rows={5}
                      name="routines"
                      value={childDailyRoutineData?.routines || ""}
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}

                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("routines")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "routines"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Likes/Dislikes</Form.Label>
                    <Form.Control 
                      style={{ resize: "none" }} 
                      type="text"
                      as="textarea"
                      rows={5}
                      name="likes_dislikes"
                      value={childDailyRoutineData?.likes_dislikes || ""}
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}

                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("likes_dislikes")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "likes_dislikes"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Comforter</Form.Label>
                    <Form.Control 
                      style={{ resize: "none" }} 
                      type="text"
                      as="textarea"
                      rows={5}
                      name="comforter"
                      value={childDailyRoutineData?.comforter || ""}
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}

                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("comforter")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "comforter"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Religion</Form.Label>
                    <Form.Control 
                      style={{ resize: "none" }} 
                      type="text"
                      as="textarea"
                      rows={5}
                      name="religion"
                      value={childDailyRoutineData?.religion || ""}
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}

                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("religion")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "religion"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Dietary Requirement</Form.Label>
                    <Form.Control 
                      style={{ resize: "none" }} 
                      type="text"
                      as="textarea"
                      rows={5}
                      name="dietary_requirement"
                      value={childDailyRoutineData?.dietary_requirement || ""}
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}

                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("dietary_requirement")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "dietary_requirement"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Allergy</Form.Label>
                    <Form.Control
                      style={{ resize: "none" }}  
                      type="text"
                      as="textarea"
                      rows={5}
                      name="allergy"
                      value={childDailyRoutineData?.allergy || ""}
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}

                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("allergy")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "allergy"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3 relative">
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      style={{ resize: "none" }} 
                      type="text"
                      as="textarea"
                      rows={5}
                      name="comment"
                      value={childDailyRoutineData?.comment || ""}
                      onChange={(e) => {
                        handleDailyRoutineData(e);
                      }}

                      onBlur={(e) => {
                        if(!childDailyRoutineData.log.includes("comment")) {
                          setChildDailyRoutineData(prevState => ({
                            ...prevState,
                            log: [...childDailyRoutineData.log, "comment"]
                          }));
                        }
                      }} />
                  </Form.Group>
                </Col>
              </Row>
            </div>

          </div>
          <div className="enrollment-form-column mt-4">
            <h2 className="title-xs mb-4">Sun Protection agreement and permission</h2>

            <div className="grayback">
              <p>I understand Mona FDC is a registered SunSmart Early Childhood Program member and follows SunSmart and Cancer Council Victoria recommendations to use a combination of sun protection measures (clothing, sunscreen, a hat, shade, and if practical, sunglasses) during the daily local sun protection times (whenever UV levels reach 3 or higher), typically from mid-August to the end of April in Victoria.</p>
              <p>I agree to help support this membership and help minimize my child’s potential risk of skin and eye damage and skin cancer by doing the following:</p>
              <p><strong>(Please tick all that apply)</strong></p>
              <Form.Group className="mb-3">
                <div className="btn-checkbox">
                  <Form.Check 
                    type="checkbox" 
                    id="dress"
                    checked={agreement?.dress_child || false} 
                    label="Dress my child in cool clothing that covers as much skin as possible e.g. tops that cover the shoulders, arms and chest, has higher necklines or collars, and long shorts and skirts. I understand that singlet tops or shoestring dresses do not provide adequate sun protection and are best layered with a shirt or t-shirt."
                    onChange={() => {
                      setAgreement(prevState => ({
                        ...prevState,
                        dress_child: !agreement.dress_child
                      }));

                      if(!agreement.log.includes("dress_child")) {
                        setAgreement(prevState => ({
                          ...prevState,
                          log: [...agreement.log, "dress_child"]
                        }));
                      }
                    }} />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="btn-checkbox">
                  <Form.Check 
                    type="checkbox" 
                    id="remind" 
                    checked={agreement?.remind_child || false} 
                    label="Remind my child to bring and wear a sun-protective hat that shades the face, neck and ears (e.g. wide-brimmed, bucket or legionnaire hat). I understand that baseball / peak style caps do not provide adequate sun protection and are not appropriate for outdoor play."
                    onChange={() => {
                      setAgreement(prevState => ({
                        ...prevState,
                        remind_child: !agreement.remind_child
                      }));

                      if(!agreement.log.includes("remind_child")) {
                        setAgreement(prevState => ({
                          ...prevState,
                          log: [...agreement.log, "remind_child"]
                        }));
                      }
                    }} />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="btn-checkbox">
                  <Form.Check 
                    type="checkbox" 
                    id="provide" 
                    checked={agreement?.provide_child || false} 
                    label="Provide my child with appropriate close-fitting wrap-around sunglasses labelled AS:1067 to help protect their eyes."
                    onChange={() => {
                      setAgreement(prevState => ({
                        ...prevState,
                        provide_child: !agreement.provide_child
                      }));

                      if(!agreement.log.includes("provide_child")) {
                        setAgreement(prevState => ({
                          ...prevState,
                          log: [...agreement.log, "provide_child"]
                        }));
                      }
                    }} />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="btn-checkbox">
                  <Form.Check 
                    type="checkbox" 
                    id="permission" 
                    checked={agreement?.give_permission_for_sunscreen || false} 
                    label="Give permission for educWators/staff to apply SPF30 (or higher) broad-spectrum, water-resistant sunscreen supplied by the service to all exposed parts of my child’s skin including their face, neck, ears, arms and legs."
                    onChange={() => {
                      setAgreement(prevState => ({
                        ...prevState,
                        give_permission_for_sunscreen: !agreement.give_permission_for_sunscreen
                      }));

                      if(!agreement.log.includes("give_permission_for_sunscreen")) {
                        setAgreement(prevState => ({
                          ...prevState,
                          log: [...agreement.log, "give_permission_for_sunscreen"]
                        }));
                      }
                    }} />
                </div>
              </Form.Group>
              <div className="text-center mb-3">OR</div>
              <Form.Group className="mb-3">
                <div className="btn-checkbox">
                  <Form.Check 
                    type="checkbox" 
                    id="gpermission" 
                    label="To give permission for educators/staff to apply SPF30 (or higher) broad-spectrum, water-resistant sunscreen (that I have supplied and labelled with my child/children’s name) to all exposed parts of my child’s skin including their face, neck, ears, arms and legs. I agree that this sunscreen will be kept at the service and it is my responsibility to make sure there is always an adequate supply available." />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="btn-checkbox">
                  <Form.Check 
                    type="checkbox" 
                    id="educators" 
                    checked={agreement?.assist_child || false} 
                    label="To give permission for educators/staff to assist my child to develop independent, self-help skills by applying SPF30 (or higher) broad-spectrum, water-resistant sunscreen to all exposed parts of their own skin including their face, neck, ears, arms and legs. (Recommended from ages three and above) "
                    onChange={() => {
                      setAgreement(prevState => ({
                        ...prevState,
                        assist_child: !agreement.assist_child
                      }));

                      if(!agreement.log.includes("assist_child")) {
                        setAgreement(prevState => ({
                          ...prevState,
                          log: [...agreement.log, "assist_child"]
                        }));
                      }
                    }} />
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="cta text-center mt-5 mb-5">
            <Button variant="outline" type="submit" onClick={prevStep} className="me-3">Go Back</Button>
            <Button 
              variant="primary" 
              type="submit">
              {loader === true ? (
                <>
                  <img
                  style={{ width: '24px' }}
                  src={'/img/mini_loader1.gif'}
                  alt=""
                  />
                    {
                      localStorage.getItem('user_role') === 'guardian'
                      ? "Saving..."
                      : "Submitting..."
                    }
                </>
              ) : (localStorage.getItem('user_role') === 'guardian' ? 'Next' : 'Submit')}
            </Button>
          </div>
          {/* <div className="cta text-center mt-5 mb-5">
              <Button variant="outline" type="submit" onClick={prevStep} className="me-3">Previous</Button>
              <Button variant="primary" type="submit">Next</Button>
            </div> */}
        </Form>
        {/* <Form onSubmit={submitFormData}>

          
        </Form> */}
      </div>
    </>
  );
};

export default ChildEnrollment5;

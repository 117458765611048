import { MonaRoles } from "./constant";

export const RoleViseFormResponseView = {
    "franchisor_admin": [MonaRoles.franchisorAdmin, MonaRoles.franchiseeAdmin, MonaRoles.coordinator, MonaRoles.educator, MonaRoles.guardian, MonaRoles.parent],
    "franchisee_admin": [MonaRoles.franchiseeAdmin, MonaRoles.coordinator, MonaRoles.educator, MonaRoles.guardian, MonaRoles.parent],
    "coordinator": [MonaRoles.coordinator, MonaRoles.educator, MonaRoles.guardian, MonaRoles.parent],
    "educator": [MonaRoles.educator, MonaRoles.guardian, MonaRoles.parent],
    "guardian": [MonaRoles.guardian, MonaRoles.parent],
    "parent": [MonaRoles.guardian, MonaRoles.parent],
}

export const RoleViseFormCreate = [MonaRoles.franchisorAdmin, MonaRoles.franchiseeAdmin];
exports.suburbData = [
  {
    id: 1,
    value: 'Sydney',
    label: 'Sydney'
  },
  {
    id: 2,
    value: 'Abba River',
    label: 'Abba River'
  },
  {
    id: 3,
    value: 'Abbeywood',
    label: 'Abbeywood'
  },
  {
    id: 4,
    value: 'Fawcetts Plain',
    label: 'Fawcetts Plain'
  },
  {
    id: 5,
    value: 'Abbotsbury',
    label: 'Abbotsbury'
  },
  {
    id: 6,
    value: 'Palmer',
    label: 'Palmer'
  },
  {
    id: 7,
    value: 'Sanctuary Cove',
    label: 'Sanctuary Cove'
  },
  {
    id: 8,
    value: 'Upper Myall',
    label: 'Upper Myall'
  },
  {
    id: 9,
    value: 'Salem',
    label: 'Salem'
  },
]
exports.userRoles = [
  {
    id: 1,
    value: 'Franchisor_Admin',
    label: 'Franchisor Admin'
  },
  {
    id: 2,
    value: 'Franchisee_Admin',
    label: 'Franchise Admin'
  },
  {
    id: 3,
    value: 'Coordinator',
    label: 'Coordinator'
  },
  {
    id: 4,
    value: 'Educator',
    label: 'Educator'
  },
  {
    id: 5,
    value: 'Guardian',
    label: 'Guardian'
  },
]
import React, { useState } from "react";
//import moment from "moment";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import FormResponseAudit from "./FormResponseAudit";
import { formatDate1 } from "./FormResponseAudit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

// function formatDate(dateString) {
//   let date = moment(dateString).format("Do MMMM YYYY");
//   let time = moment(dateString).format("HH:mm A");

//   return `${date}, ${time}`;
// }
const h6Css1 = { fontSize: "16px", color: "#455c58" }

function FormAccordion({ data: formResponse, editable, formData }) {
  const { userId, userRole } = useParams();
  //const token = localStorage.getItem('token');
  let count = 0;
  //const [loadHistory, setLoadHistory] = useState(false);
  console.log(formData);


  const GenerateContent = (item, inner_index, inner_item) => {

    const FieldKeys = Object.keys(JSON.parse(item.fields));
    const FieldValues = Object.values(JSON.parse(item.fields));
    const FK = FieldKeys[inner_index];
    const FV = FieldValues[inner_index];

    if (inner_index === 0) { count = 0; }
    const hasHeading = (FK?.includes("headings") || FK?.includes("text_headings") || FK?.includes("sub_headings"));

    if (hasHeading) { count += 1; }
    return (
      <div key={inner_index} className="responses-content-box" style={{ marginTop: "12px" }} >
        <div className="responses-content-question">
          {!hasHeading && <span>{inner_index + 1 - count}</span>}
          {FK?.includes("headings") ? (
            <h6 className="text-capitalize" style={{ fontSize: "20px", color: "#AA0061", }} > {FV} </h6>
          ) : FK?.includes("text_headings") ? (<h6 className="text-capitalize" style={h6Css1} > {FV} </h6>
          ) : FK?.includes("text_headings") ? (<h6 className="text-capitalize" style={h6Css1} > {FV} </h6>
          ) : (<h6 className="text-capitalize">{inner_item.split("_").join(" ")}</h6>)}
        </div>
        <div className="responses-content-answer">
          {!hasHeading && <img src="../img/bx_right-arrow-alt.svg" alt="" />}

          {FV?.includes("data:image") || FV?.includes(".png") || FV?.includes(".jpg") || FV?.includes(".jpeg") ? (
            <> <img style={{ height: "40px", width: "51px", }} src={`${FV}`} ></img> </>
          ) : FV?.includes(".doc") || FV?.includes(".docx") || FV?.includes(".html") || FV?.includes(".htm") || FV?.includes(".odt") || FV?.includes(".xls") || FV?.includes(".xlsx") || FV?.includes("ods") || FV?.includes(".ppt") || FV?.includes(".pptx") || FV?.includes(".pdf") || FV?.includes(".txt") ? (
            <a role="button" href={FV} download > <p> {FV.split("/")[FV.split("/").length - 1]} </p> </a>
          ) : (!hasHeading && <p>{FV}</p>)}
        </div>
      </div>
    );
  }

  return (
    <Accordion>
      {formResponse.map((item, index) => (
        <>
          <Accordion.Item key={index} eventKey={index}>
            <Accordion.Header>
              <div className="responses-header-row">
                <div className="responses-header-left">
                  <div className="responses-header-image"> <img src={item?.user.profile_photo ? item?.user.profile_photo : "/img/upload.jpg"} alt="response filling user" /> </div>
                  <div className={"responses-header-detail"}>
                    <div className="d-flex">
                      <h5>{item?.user?.fullname}</h5>
                      {editable && (
                        <Link style={{ marginLeft: "5px" }} to={`/form/dynamic/${formData.form_name}`}
                          state={{ id: item?.id, form_id: formData.form_id || null, edit_from: "user", user_id: userId, user_role: userRole, }} >
                          <FontAwesomeIcon icon={faPen} style={{ color: "#455C58", marginRight: "10px", }} />
                        </Link>
                      )}
                    </div>
                    <h6> <span className="text-capitalize">{item?.user?.role}</span> </h6>
                  </div>
                </div>
                <div className="responses-header-right">
                  {item?.updated ? (
                    <p> Last updated By:&nbsp;&nbsp; {item?.updatedBy?.fullname} <br />Updated At: &nbsp;&nbsp; {formatDate1(item?.updatedAt)} </p>
                    // <p>fullname Updated At: {formatDate1(item?.updatedAt)}</p>
                  ) : (<p>Created At: {formatDate1(item?.createdAt)}</p>)}
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body style={{ paddingTop: "10px" }}>
              {/* {item.response.length > 0 &&
              item.response.map((response, index) => ( */}
              <div key={index} className={index === 0 ? "responses-content-wrap" : "responses-content-wrap response-margin"} >
                <div className="content-wrap-title text-capitalize d-md-flex">
                  <h4> {item?.behalf_of?.fullname ? `${item?.user?.fullname}` : `Filled By: ${item?.user?.fullname}`}{" "} {item?.behalf_of?.fullname ? `| Name: ${item?.behalf_of?.fullname}` : ``} </h4>
                </div>
                {Object.keys(JSON.parse(item.fields)).map((inner_item, inner_index) => GenerateContent(item, inner_index, inner_item))}
              </div>
              {/* ))} */}
              <FormResponseAudit frData={item} />
            </Accordion.Body>
          </Accordion.Item>
        </>
      ))}
    </Accordion>
  );
}

export default FormAccordion;

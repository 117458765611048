export const getSessionItem = (key) => {
    const a = sessionStorage.getItem(key);;
    if (a) {
        return JSON.parse(a);
    }
    else {
        return null;
    }
}

export const setSessionItem = (key, val) => {
    sessionStorage.setItem(key, JSON.stringify(val));
}
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getAuthToken } from '../../../../utils/commonMethods';
import { getFormResponseAPI } from './formResponseAPI';
import moment from 'moment';

export const FetchFormResponse = ({ formId, userId, search }) => {
  const [formDetails, setFormDetails] = useState(null);
  const [formResponse, setFormResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formDataUpdateHist, setFormDataUpdateHist] = useState(null);
  



  const fetchFormResponses = useCallback(async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    };

    await axios
      .get(getFormResponseAPI({ formId, userId, search }), config)
      .then((response) => {

const dataa = {
  "status": true,
  "formDetails": {
      "form_name": "Comm W Guardian V2",
      "form_type": "Multi Submission",
      "form_response_count": 9,
      "form_category": "General",
      "createdAt": "9th November 2023, 11:36 AM",
      "created_by": {
          "id": 2,
          "fullname": "Nazanin Ghanbarnejad",
          "role": "Franchisor Admin",
          "email": "ngh12345@bigpond.com",
          "profile_photo": "https://s3.us-west-1.amazonaws.com/mona-cip-dev/public/assets/blob_2022-11-01_1667299330267.blob"
      }
  },
  "formResponse": [
      {
          "id": 2865,
          "fields": "{\"headings_0\":\"Comm W guardians V2\",\"date_of_communication_\":\"08-12-2023\",\"note\":\"Sent her a message to provide the office with the following documents:\\ncourt order\\nemergency contacts full details\\na photo of their concession card.\",\"please_attache_supporting_image\":null,\"please_attach_supporting_document\":null}",
          "createdAt": "2023-12-22T02:32:56.868Z",
          "user_id": 610,
          "behalf_of": {
              "id": 1328,
              "fullname": "Neda Alinajad",
              "role": "guardian",
              "email": "neda.alinejad27@yahoo.com",
              "profile_photo": null
          },
          "behalf_of_type": "user",
          "user": {
              "id": 610,
              "fullname": "Neda Eghterafi Najafabadi",
              "role": "coordinator",
              "email": "gbahar14@yahoo.com",
              "profile_photo": null
          }
      },
      {
          "id": 2866,
          "fields": "{\"headings_0\":\"Comm W guardians V2\",\"date_of_communication_\":\"11-12-2023\",\"note\":\"Sent her a message to confirm the children's enrolment again.\",\"please_attache_supporting_image\":null,\"please_attach_supporting_document\":null}",
          "createdAt": "2023-12-22T02:34:33.412Z",
          "user_id": 610,
          "behalf_of": {
              "id": 1328,
              "fullname": "Neda Alinajad",
              "role": "guardian",
              "email": "neda.alinejad27@yahoo.com",
              "profile_photo": null
          },
          "behalf_of_type": "user",
          "user": {
              "id": 610,
              "fullname": "Neda Eghterafi Najafabadi",
              "role": "coordinator",
              "email": "gbahar14@yahoo.com",
              "profile_photo": null
          }
      },
      {
          "id": 2867,
          "fields": "{\"headings_0\":\"Comm W guardians V2\",\"date_of_communication_\":\"12-12-2023\",\"note\":\"She messaged me to send her a pin. however the office generated the pin for her already.\",\"please_attache_supporting_image\":null,\"please_attach_supporting_document\":null}",
          "createdAt": "2023-12-22T02:38:05.970Z",
          "user_id": 610,
          "behalf_of": {
              "id": 1328,
              "fullname": "Neda Alinajad",
              "role": "guardian",
              "email": "neda.alinejad27@yahoo.com",
              "profile_photo": null
          },
          "behalf_of_type": "user",
          "user": {
              "id": 610,
              "fullname": "Neda Eghterafi Najafabadi",
              "role": "coordinator",
              "email": "gbahar14@yahoo.com",
              "profile_photo": null
          }
      },
      {
          "id": 2416,
          "fields": "{\"headings_0\":\"Comm W guardians V2\",\"date_of_communication_\":\"11-12-2023\",\"note\":\"sent her a message to confirm her children's enrolment through centrelink online account.\",\"please_attache_supporting_image\":null,\"please_attach_supporting_document\":null}",
          "createdAt": "2023-12-10T23:51:00.326Z",
          "user_id": 610,
          "behalf_of": {
              "id": 1328,
              "fullname": "Neda Alinajad",
              "role": "guardian",
              "email": "neda.alinejad27@yahoo.com",
              "profile_photo": null
          },
          "behalf_of_type": "user",
          "user": {
              "id": 610,
              "fullname": "Neda Eghterafi Najafabadi",
              "role": "coordinator",
              "email": "gbahar14@yahoo.com",
              "profile_photo": null
          }
      },
      {
          "id": 3688,
          "fields": "{\"comm_w_guardians_v2\":null,\"date_of_communication_\":\"27-03-2024\",\"note\":\"I sent an email to Neda regarding her overdue invoices, which are -$5751.71.\",\"please_attache_supporting_image\":null,\"please_attach_supporting_document\":null,\"headings_0\":\"Comm W guardians V2\"}",
          "createdAt": "2024-03-27T01:24:51.387Z",
          "user_id": 610,
          "behalf_of": {
              "id": 1328,
              "fullname": "Neda Alinajad",
              "role": "guardian",
              "email": "neda.alinejad27@yahoo.com",
              "profile_photo": null
          },
          "behalf_of_type": "user",
          "user": {
              "id": 610,
              "fullname": "Neda Eghterafi Najafabadi",
              "role": "coordinator",
              "email": "gbahar14@yahoo.com",
              "profile_photo": null
          }
      },
      {
          "id": 3618,
          "fields": "{\"headings_0\":\"Comm W guardians V2\",\"date_of_communication_\":\"19-02-2024\",\"note\":\"Phone call to Neda to follow up on her court order, as the one she provided us is incomplete. She said that's the only one she has. She said the next hiring will be on February 28. She will provide with the office soon after that. \\nI also reminded her about the outstanding payment; she claimed that she hadn't received any invoices. Apparently, the email she provided to us at the beginning wasn't her actual email address. She is supposed to provide us with the proper email address.\\nShe also mentioned that she is a single mother and has financial difficulty. I offered her the payment plan. She didn't agree on the suggested amount to be paid to the office fortnightly.\",\"please_attache_supporting_image\":null,\"please_attach_supporting_document\":null}",
          "createdAt": "2024-02-20T01:57:18.280Z",
          "user_id": 610,
          "behalf_of": {
              "id": 1328,
              "fullname": "Neda Alinajad",
              "role": "guardian",
              "email": "neda.alinejad27@yahoo.com",
              "profile_photo": null
          },
          "behalf_of_type": "user",
          "user": {
              "id": 610,
              "fullname": "Neda Eghterafi Najafabadi",
              "role": "coordinator",
              "email": "gbahar14@yahoo.com",
              "profile_photo": null
          }
      },
      {
          "id": 2869,
          "fields": "{\"headings_0\":\"Comm W guardians V2\",\"date_of_communication_\":\"22-12-2023\",\"note\":\" I sent her a message to follow up on the court order. She said the one she provided me, was the only copy she had. She said the children's father is not living in Melbourne; however, there is no restriction for him to visit his children.\",\"please_attache_supporting_image\":null,\"please_attach_supporting_document\":null}",
          "createdAt": "2023-12-22T02:45:51.212Z",
          "user_id": 610,
          "behalf_of": {
              "id": 1328,
              "fullname": "Neda Alinajad",
              "role": "guardian",
              "email": "neda.alinejad27@yahoo.com",
              "profile_photo": null
          },
          "behalf_of_type": "user",
          "user": {
              "id": 610,
              "fullname": "Neda Eghterafi Najafabadi",
              "role": "coordinator",
              "email": "gbahar14@yahoo.com",
              "profile_photo": null
          }
      },
      {
          "id": 3619,
          "fields": "{\"headings_0\":\"Comm W guardians V2\",\"date_of_communication_\":\"20-02-2024\",\"note\":\"The office sent Neda the four overdue invoices to the following email address.\\nneda.alinejad27@yahoo.com\",\"please_attache_supporting_image\":null,\"please_attach_supporting_document\":null}",
          "createdAt": "2024-02-20T01:59:45.442Z",
          "user_id": 610,
          "behalf_of": {
              "id": 1328,
              "fullname": "Neda Alinajad",
              "role": "guardian",
              "email": "neda.alinejad27@yahoo.com",
              "profile_photo": null
          },
          "behalf_of_type": "user",
          "user": {
              "id": 610,
              "fullname": "Neda Eghterafi Najafabadi",
              "role": "coordinator",
              "email": "gbahar14@yahoo.com",
              "profile_photo": null
          }
      },
      {
          "id": 2868,
          "fields": "{\"headings_0\":\"Comm W guardians V2\",\"date_of_communication_\":\"15-12-2023\",\"note\":\"Phone call to Neda to see if there is a complete court order in place, as the court order she sent me. looks incomplete. She is supposed to double-check and provide me with the proper court order.\",\"please_attache_supporting_image\":null,\"please_attach_supporting_document\":null}",
          "createdAt": "2023-12-22T02:41:04.958Z",
          "user_id": 610,
          "behalf_of": {
              "id": 1328,
              "fullname": "Neda Alinajad",
              "role": "guardian",
              "email": "neda.alinejad27@yahoo.com",
              "profile_photo": null
          },
          "behalf_of_type": "user",
          "user": {
              "id": 610,
              "fullname": "Neda Eghterafi Najafabadi",
              "role": "coordinator",
              "email": "gbahar14@yahoo.com",
              "profile_photo": null
          }
      }
  ],
  "msg": "form responses fetched successfully"
}

        //let { formResponse: userFormResponse, formDetails } = dataa;//response.data;
        let { formResponse: userFormResponse, formDetails ,formDataUpdateHist} = response.data;
        setFormDetails(formDetails);
        setFormResponse(userFormResponse);
        setFormDataUpdateHist(formDataUpdateHist);
        setIsLoading(false);
      })
      .catch((error) => {
        setError('No Resonse Found');
        setIsLoading(false);
      });
  }, [formId, search]);

  useEffect(() => {
    fetchFormResponses();
  }, [fetchFormResponses]);

  return { formDetails, formResponse, error, isLoading ,formDataUpdateHist};
};

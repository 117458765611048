import React from "react";

const Welcomemsg = () => {
  return (
    <>
      <div className="custom_content">
        <div className="custom_content-col">
          <div className="custom_logo">
            <div className="custom_logo_image">
              <img src="../img/logo.svg" alt="website logo"></img>
            </div>
          </div>
          <p className="custom_main_title">Welcome</p>
          <p className="custom_main_content"> Mona Franchise Centralized Information System - Intranet for all important documents, forms, announcements and notifications.</p>
        </div>
      </div>
    </>
  );
}
export default Welcomemsg;
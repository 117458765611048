const MonaRoles = {
    franchisorAdmin: "franchisor_admin",
    franchiseeAdmin: "franchisee_admin",
    coordinator: "coordinator",
    educator: "educator",
    guardian: "guardian" || "parent",
    child: "child",
    parent: "parent"
}

const FormTypes = {
    singleNonEdit: "",
    multiSubmissionEdit: "multi_submission",
    singleEdit: "editable"
}

const SessionKeys = {
    RolesFranchiseeList: "ROLE_FRANCHISEE_LIST"
}

const AppConfig = {

}

const DateFormat = {
    dmy: "DD/MM/YYYY"
}

const IsFormEditable = (formtype) => {
    const editableForms = [FormTypes.singleEdit, FormTypes.multiSubmissionEdit];
    return (editableForms.includes(formtype));
}

const checkIfImage = (val) => {
    const types = ["data:image", ".png", ".jpg", ".jpeg"]
    return types.includes(val);
}

const GetCellRole = (cellVal) => {
    return cellVal === MonaRoles.franchisorAdmin ? "Franchisor Admin" : cellVal === MonaRoles.franchiseeAdmin ? "Franchisee Admin" : cellVal === MonaRoles.guardian ? "Guardian" : cellVal === MonaRoles.educator ? "Educator" : cellVal === MonaRoles.coordinator ? "Coordinator" : cellVal
}

export { MonaRoles, AppConfig, DateFormat, GetCellRole, FormTypes, IsFormEditable, checkIfImage ,SessionKeys}
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import { BASE_URL } from '../components/App';
import { useNavigate } from 'react-router'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const EducatorAssignPopup = (props) => {
    console.log('PROPS:>>>>>>>>>>>>>>>>>>>>', props);
    const navigate = useNavigate();
    const { SearchBar } = Search;
    //   const [show, setShow] = useState(false);

    const [selectedEducators, setSelectedEducators] = useState([])
    const handleClose = () => props.handleClose();
    const assignEducators = async () => {
        console.log(JSON.parse(localStorage.getItem("DefaultEducators")), "default")
        let removedEducators = JSON.parse(localStorage.getItem("DefaultEducators")).map((educator) => {
            if (!selectedEducators.includes(educator)) {
                console.log("andar aa raha hai")
                return educator
            }
        })
        removedEducators = removedEducators.filter(function (element) {
            return element !== undefined;
        });

        console.log(removedEducators, selectedEducators, "eduuca")

        let childId = localStorage.getItem("SelectedChild")
        let response = await axios.post(`${BASE_URL}/enrollment/child/assign-educators/${childId}`, { educatorIds: selectedEducators, removedEducatorIds: removedEducators }, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        if (response.status === 201) {
            handleClose()
            window.location.reload()
        }
    }

    useEffect(() => {
        const defaultEducators = JSON.parse(localStorage.getItem("DefaultEducators"))
        setSelectedEducators(defaultEducators)
    }, [])

    const selectRow = {
        mode: 'checkbox',
        selected: [...selectedEducators],
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                let arr = selectedEducators
                arr.push(row.id)
                setSelectedEducators(arr)
            }
            else {
                let arr = selectedEducators
                let index = arr.indexOf(row.id)
                let removed = arr.splice(index, 1);
                setSelectedEducators(arr)
            }
        },
        onSelectAll: (isSelect, rows, e) => {

            if (isSelect) {
                rows = rows.map((row) => {
                    return row.id
                })
                setSelectedEducators(rows)
            }
            else {
                setSelectedEducators([])
            }
        }
    };

    const products = props.educators.map((educator) => ({
        id: educator.id,
        name: educator.fullname + "," + (educator.profile_photo ? educator.profile_photo : "../img/user.png"),
        Location: educator.city
    }))

    const PopColumns = [
        {
            dataField: 'name',
            text: 'Name',
            formatter: (cell) => {
                cell = cell.split(",");
                return (<><div className="user-list"><span className="user-pic"><img src={cell[1]} alt='' /></span><span className="user-name">{cell[0]}</span></div></>)
            },
        },
        { dataField: 'Location', text: 'Location', }
    ];

    return (
        <>
            <div className="item mb-3">
                <Modal size="lg" show={props.show} onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Select Educator</Modal.Title>
                        <Button variant="outline-secondary" onClick={() => (navigate(`/new-user?franchise=${props.franchise}&childId=${props.childId}&role=educator&parentId=${props.paramsParentId}&educators=${props.assignedEducators}`))} style={{ position: 'absolute', right: '80px' }}>
                            Add New
                        </Button>
                    </Modal.Header>
                    <Modal.Body style={{ height: "40rem", overflowY: "scroll" }}>
                        <ToolkitProvider bootstrap4 keyField="id" data={products} columns={PopColumns} search >
                            {(props) => (
                                <div className="data-search me-3">
                                    <label htmlFor="search-bar" className="search-label">
                                        {/* <input
                                id="search-bar"
                                type="text"
                                className="form-control"
                                placeholder="Search"
                            // value={search}
                            // onChange={(e) => {
                            //     setSearch(e.target.value);
                            // }}

                            /> */}
                                        <SearchBar {...props.searchProps} />
                                    </label>
                                    <div className="mb-3"></div>
                                    {/* </div>
                    <div className="column-table user-management-sec user_management_sec"> */}
                                    <BootstrapTable selectRow={selectRow} {...props.baseProps} noDataIndication="There is no similar Educator!" />
                                </div>
                            )}
                        </ToolkitProvider>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-md-center">
                        <Button variant="transparent" onClick={handleClose}> Cancel </Button>
                        <Button variant="primary" onClick={() => assignEducators()}> Add </Button>
                    </Modal.Footer>
                </Modal >
            </div>
        </>
    )
}
export default EducatorAssignPopup;

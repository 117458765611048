import React, { useState } from "react";
import moment from "moment";
//import { useEffect } from 'react';
import { BASE_URL } from "./App";

// function formatDateOld(dateString) {
//   let date = moment(dateString).format('Do MMMM YYYY');
//   let time = moment(dateString).format('HH:mm A');

//   return `${date}, ${time}`;
// }
const cssObj1 = { float: "right", cursor: "pointer" }

export function formatDate1(dateString) {
  return `${moment(dateString).format("DD/MM/YYYY") + ", " + moment(dateString).subtract(13, "hours").utc().format("HH:mm")} hrs`;
}

function FormResponseAudit(props) {
  const token = localStorage.getItem("token");
  //let count = 0;
  const [loadHistory, setLoadHistory] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [auditData, setAuditData] = useState([]);

  const { frData } = props;

  const getAuditData = () => {
    if (!dataLoaded) {
      //console.log(frData);
      const URL_ = `${BASE_URL}/form/response/edit/history?form_response_id=${frData.id}`;
      var myHeaders = new Headers();
      myHeaders.append("authorization", "Bearer " + token);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
      };
      fetch(URL_, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setAuditData(result?.result);
          setDataLoaded(true);
        });
    }
  };
  //useEffect(() => {}, [frData])

  return (
    <>
      <div>
        {!frData.updated && (
          <p style={cssObj1}> Completed By:&nbsp;&nbsp; {frData.filled_user?.fullname || frData.user?.fullname} &nbsp;&nbsp; | &nbsp;&nbsp;{formatDate1(frData.createdAt)} &nbsp;&nbsp;&nbsp;&nbsp; </p>
        )}
        {frData.updated && (
          <p onClick={() => { setLoadHistory(!loadHistory); getAuditData(); }} style={cssObj1} >
            Last Updated By:&nbsp;&nbsp;{" "}
            {frData.updatedByUsers ? frData.updatedByUsers[0]?.fullname : frData.updatedBy?.fullname}
            &nbsp;&nbsp; | &nbsp;&nbsp;{formatDate1(frData.updatedAt)} &nbsp;&nbsp;&nbsp;&nbsp;
            <a href={void 0} style={{ fontSize: "25px", fontWeight: "300", verticalAlign: "sub" }} >{`${loadHistory ? "-" : "+"}`}</a>
          </p>
        )}
        {auditData && auditData.length > 0 && (
          <>
            <div style={{ clear: "both" }}></div>
            {loadHistory && (
              <div style={{ float: "right" }}>
                {auditData.length > 1 &&
                  auditData.map((ad, i) => (
                    <p> Updated By:&nbsp;&nbsp; {ad?.user.fullname} &nbsp;&nbsp; | &nbsp;&nbsp;{" "} {formatDate1(ad?.updatedAt)} </p>
                  ))}
              </div>
            )}
            <div style={{ clear: "both" }}></div>
          </>
        )}
      </div>

      {/* {auditData && auditData.length > 0 &&
        <div>      
          <p onClick={() => setLoadHistory(!loadHistory)} style={{ float: 'right', cursor: "pointer" }}>Last Updated By:&nbsp;&nbsp;  {auditData[0]?.user.fullname}&nbsp;&nbsp; | &nbsp;&nbsp;{formatDate1(auditData[0].updatedAt)} &nbsp;&nbsp;&nbsp;&nbsp;
          {auditData.length > 1 && <a href={void (0)} style={{ fontSize: "25px", fontWeight: "300", verticalAlign: "sub" }} >{`${loadHistory ? "-" : "+"}`}</a>}            
            </p>
          <div style={{ clear: "both" }}></div>
          {loadHistory && <div style={{ float: 'right' }}>
            {auditData.length > 1 && auditData.map((ad, i) =>
              <p>Updated By:&nbsp;&nbsp;   {ad?.user.fullname} &nbsp;&nbsp; | &nbsp;&nbsp; {formatDate1(ad?.updatedAt)}</p>
            )}
          </div>}
          <div style={{ clear: "both" }}></div>
        </div>
      } */}

      {/* {frData && frData.length> 0 && auditData.length==0  && 
        <p>
          Last Updated By : {frData[0]?.updatedByUsers[0]?.fullname}{" "}<br />
          Updated on: <br />{formatDate1(frData[0].updatedAt)} hrs
        </p>
      } */}
    </>
  );
}

export default FormResponseAudit;

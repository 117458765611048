import React, { useState, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import { Link } from 'react-router-dom';
import { BASE_URL } from '../components/App';
import { FullLoader } from "../components/Loader";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { GetCellRole, MonaRoles } from '../constant';
const FileRepoShairWithme = ({ selectedFranchisee, SearchValue }) => {
  const [userData, setUserData] = useState([]);
  const [fullLoaderStatus, setfullLoaderStatus] = useState(true);

  const LSToken = localStorage.getItem('token');
  const AxiosHeaders = { headers: { Authorization: `Bearer ${LSToken}` } }

  const GetData = async () => {
    try {
      let User = localStorage.getItem('user_role');
      let URL = User === "guardian" ? `${BASE_URL}/fileRepo?childId=[${selectedFranchisee}]` : `${BASE_URL}/fileRepo/`
      let response = await axios.get(URL, AxiosHeaders)
      if (response.status === 200) {
        const users = response.data.dataDetails;
        let tempData = users.map((dt) => ({
          name: `${dt.categoryName},${dt.count},${dt?.categoryId}`,
          updatedAt: dt?.updatedAt,
          createdAt: dt?.createdAt,
          userID: dt?.id,
          creatorName: dt?.ModifierName + "," + dt?.updatedBy
        }));
        setUserData(tempData);
        setfullLoaderStatus(false)
      }
      setfullLoaderStatus(false)
    } catch (e) {
      setfullLoaderStatus(false)
    }
  }

  // useEffect(() => {
  //   GetData();
  // }, []);

  useEffect(() => {
    if (selectedFranchisee != null) {
      GetData();
    }
  }, [selectedFranchisee]);


  const GetSaachhData = async () => {
    try {
      let User = localStorage.getItem('user_role');
      let URL = User === "guardian" ? `${BASE_URL}/fileRepo?childId=[${selectedFranchisee}]&search=${SearchValue}` : `${BASE_URL}/fileRepo?search=${SearchValue}`
      let response = await axios.get(URL, AxiosHeaders)
      if (response) {
        setfullLoaderStatus(false)
      }
      if (response.status === 200) {
        const users = response.data.dataDetails;
        let tempData = users.map((dt) => ({
          name: `${dt?.categoryName},${dt?.count},${dt?.categoryId}`,
          updatedAt: dt?.updatedAt,
          createdAt: dt?.createdAt,
          userID: dt?.id,
          creatorName: dt?.ModifierName + "," + dt?.updatedBy
        }));
        setUserData(tempData);
      }

    } catch (e) {
      setfullLoaderStatus(false)
    }
  }

  useEffect(() => {
    if (selectedFranchisee != null) {
      GetSaachhData();
    }
  }, [SearchValue])

  const defaultSortedBy = [{
    dataField: "name",
    order: "asc"  // or desc
  }];


  const [columns, setColumns] = useState([
    {
      dataField: 'name', text: 'Name', sort: true,
      formatter: (cell) => {
        cell = cell.split(',');

        return (
          <>
            <div className="user-list">
              <Link to={`/file-repository-List/${cell[2]}`} className="FileResp"> <span> <img src="../img/gfolder-ico.png" className="me-2" alt="" /> </span> </Link>
              <span className="user-name"> {cell[0]} <small> {cell[1] > 1 ? (<> {cell[1]} Files </>) : (<> {cell[1]} File </>)} </small> </span>
            </div>
          </>
        );
      },
    },
    { dataField: 'createdAt', text: 'Created on', sort: true, },
    { dataField: 'updatedAt', text: 'Updated on', sort: true, },
    {
      dataField: 'creatorName', text: 'Updated by', sort: true,
      formatter: (cell) => {
        cell = cell.split(',');
        return (
          <>
            <div className="user-list">
              <span className="user-name">
                {cell[0]}
                <small>
                  {GetCellRole(cell[1])
                    //cell[1] === MonaRoles.franchisorAdmin ? "Franchisor Admin" : cell[1] === MonaRoles.franchiseeAdmin ? "Franchisee Admin" : cell[1] === MonaRoles.guardian ? "Guardian" : cell[1] === MonaRoles.educator ? "Educator" : cell[1] === MonaRoles.coordinator ? "Coordinator" : cell[1]
                  }
                </small>
              </span>
            </div>
          </>
        );
      }
    },
    {
      dataField: 'repository_files',
      text: '',
      // formatter: (cell) => {
      //   return (
      //     <>
      //       <div className="cta-col">
      //         <Dropdown>
      //           <Dropdown.Toggle variant="transparent" id="ctacol">
      //             <img src="../img/dot-ico.svg" alt="" />
      //           </Dropdown.Toggle>
      //           <Dropdown.Menu>
      //             <Dropdown.Item href="#">Delete</Dropdown.Item>
      //           </Dropdown.Menu>
      //         </Dropdown>
      //       </div>
      //     </>
      //   );
      // },
    },
  ]);


  return (
    <div>
      <FullLoader loading={fullLoaderStatus} />
      {userData.length > 0 && fullLoaderStatus === false ? (<>
        <ToolkitProvider keyField="name" data={userData} columns={columns} search defaultSorted={defaultSortedBy} >
          {(props) => (
            <>
              <BootstrapTable {...props.baseProps} defaultSorted={defaultSortedBy} pagination={paginationFactory()} />
            </>
          )}
        </ToolkitProvider>
      </>) :
        <div className="text-center mb-5 mt-5">  <strong>No File shared with You</strong> </div>
      }
      {/* {!userData && fullLoaderStatus === false ?
        <div className="text-center mb-5 mt-5">  <strong>No File shared with You</strong> </div>
        : null} */}
      {/* <div className="text-center mb-5 mt-5"><strong>No File shared with You</strong></div> */}
    </div >
  )
}

export default FileRepoShairWithme  
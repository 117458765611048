import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import LeftNavbar from "../../../components/LeftNavbar";
import TopHeader from "../../../components/TopHeader";
import Multiselect from "multiselect-react-dropdown";
import { createFormFieldValidation } from "../../../helpers/validation";
import { BASE_URL } from "../../../components/App";
import { useLocation, useNavigate } from "react-router-dom";
import Setting from "../Setting";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FullLoader } from "../../../components/Loader";
// import { cssNumber } from "jquery";
import { MonaRoles } from "../../../constant";

let counter = 0;
let selectedUserRole = [];
let selectedFillAccessUserId = "";
let selectedFillAccessUser = [];
let selectedSignatoriesUserId = "";
let selectedSignatoriesUser = [];
let signature_count = 1;
const token = localStorage.getItem("token");
const AddFormField = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [LSUserRole, setLSUserRole] = useState(localStorage.getItem("user_role"));
  const [LSUserId, setLSUserId] = useState(localStorage.getItem("user_id"));
  const [conditionFlag, setConditionFlag] = useState(false);
  const [fullLoaderStatus, setfullLoaderStatus] = useState(true);
  const [groupFlag, setGroupFlag] = useState(false);
  const [formSettingFlag, setFormSettingFlag] = useState(false);
  const [formSettingError, setFormSettingError] = useState({});
  const [removeConditionId, setRemoveConditionId] = useState([]);
  const [fieldLabel, setFieldLabel] = useState(null);
  const [count, setCount] = useState(0);
  const [Index, setIndex] = useState(1);
  const [user, setUser] = useState([]);
  const [selectedFranchisee, setSelectedFranchisee] = useState(localStorage.getItem("franchisee_id") ? (localStorage.getItem("franchisee_id") !== "none" ? localStorage.getItem("franchisee_id") : null) : null);
  const [selectedFranchiseeId, setSelectedFranchiseeId] = useState(null);
  const [franchisee, setFranchisee] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [groupModelData, setGroupModelData] = useState([]);
  const [form, setForm] = useState([{ field_type: "text" }, { field_type: "radio", option: [{ "": "" }, { "": "" }] }, { field_type: "checkbox", option: [{ "": "" }, { "": "" }] }]);
  const [formSettingData, setFormSettingData] = useState({});
  const [sectionTitle, setSectionTitle] = useState("");
  const [errors, setErrors] = useState([{}]);
  const [section, setSection] = useState([]);
  const [createSectionFlag, setCreateSectionFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState({});
  const [chosenFieldForConditionApplied, setChosenFieldForConditionApplied] = useState("");
  const [newFieldAddIndex, setNewFieldAddIndex] = useState(-1);
  const [newOptionAddIndex, setNewOptionAddIndex] = useState(-1);
  const [newConditionOptionAddIndex, setNewConditionOptionAddIndex] = useState(-1);
  const [selectedCheckBox, setSelectedCheckBox] = useState({});

  const [formData, setFormData] = useState([]);
  const [fieldData, setFieldData] = useState([]);

  const formId = location?.state?.id;
  const form_name = location?.state?.form_name ? location?.state?.form_name : null;

  useEffect(() => {
    console.log(selectedCheckBox);
  }, [selectedCheckBox]);

  useEffect(() => {
    if (form_name || (localStorage.getItem("formStatus") && localStorage.getItem("formStatus") === true)) {
      getFormField();
      getFormData();
      getUserRoleAndFranchiseeData();
      getOneFormData();
    } else {
      setfullLoaderStatus(false);
    }
  }, []);

  if (localStorage.getItem("formStatus")) {
    localStorage.removeItem("formStatus");
  }

  useEffect(() => {
    setFormSettingFlag(false);
  }, [user]);
  useEffect(() => {
    getUser();
  }, [selectedFranchisee]);

  useEffect(() => {
    if (formData) {
      getField();
    }
  }, [formData]);

  const getUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    let api_url = "";
    if (selectedFranchisee) {
      if (selectedFranchisee === "All") api_url = `${BASE_URL}/auth/users`;
      else api_url = `${BASE_URL}/user-group/users/franchisee/${selectedFranchisee}`;
    } else {
      api_url = `${BASE_URL}/auth/users`;
    }

    fetch(api_url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result?.data?.map((item) => {
          item["status"] = false;
        });
        if (selectedFranchisee) {
          if (selectedFranchisee === "All") setUser(result?.data);
          else setUser(result?.users);
        } else setUser(result?.data);
      })
      .catch((error) => console.log("error", error));
  };

  const removeCondition = (field_name) => {
    if (form[0]?.form_id) {
      // EDITING AN ALREADY EXISTING FORM
      let field_data = form.filter((d) => d.field_name === field_name);
      let atIndex;
      form.forEach((d, i) => {
        if (d.field_name === field_name) atIndex = i;
      });
      let remainingData = form.filter((d) => d.field_name !== field_name);

      let option = field_data[0].option;

      let arr = [];
      option.forEach((d) => {
        console.log("DATA>>>>>>>>>>>>", Object.values(d)[0]);
        arr.push(Object.values(d)[0].id);
      });

      setRemoveConditionId([...removeConditionId, ...arr]);

      option.forEach((d, index) => {
        option[index][`${Object.keys(d)}`] = `${Object.keys(d)}`;
      });

      let temp_field = { ...field_data[0], option: option };
      remainingData.splice(atIndex, 0, temp_field);
      // setForm([
      //   ...remainingData
      // ]);
    } else {
      // CREATING FORM FOR THE FIRST TIME
      let field_data = form.filter((d) => {
        let field =
          d.field_label
            ?.split(" ")
            ?.map((d) => d.charAt(0).toLowerCase() + d.slice(1))
            ?.join("_") || "";
        if (field === field_name) return [d];
      });

      let atIndex;
      form.forEach((d, index) => {
        let field =
          d.field_label
            ?.split(" ")
            ?.map((d) => d.charAt(0).toLowerCase() + d.slice(1))
            ?.join("_") || "";
        if (field === field_name) atIndex = index;
      });

      let remainingData = form.filter((d, index) => {
        let field =
          d.field_label
            ?.split(" ")
            ?.map((d) => d.charAt(0).toLowerCase() + d.slice(1))
            ?.join("_") || "";
        if (field !== field_name) return d;
      });

      let { option } = field_data[0];
      option = option.map((d) => ({
        [Object.keys(d)]: {
          field_type: d[Object.keys(d)].field_type,
          option: d[Object.keys(d)].option,
        },
      }));
      let temp_field = { ...field_data[0], option: option };
      console.log("TEMP DATA:", temp_field);
      remainingData.splice(atIndex, 0, temp_field);
      setForm([...remainingData]);
    }
  };

  const setCheckBoxField = (form, name, value, checked, index) => {
    // console.log('DATA CHECKBOX FIELD:::', {
    //   form,
    //   name,
    //   value,
    //   checked,
    //   index,
    // });
    let tempArr = [...form];
    let tempObj = tempArr[index];
    // console.log('Temp obj::::', tempObj);

    let obj = selectedCheckBox;

    // console.log('Selected Checkbox:::', selectedCheckBox);
    let selectedCheckboxVal = selectedCheckBox[tempObj?.section_name] || [];
    // console.log('Selected checkbox value:::', selectedCheckboxVal);
    selectedCheckboxVal = selectedCheckboxVal?.filter((item) => {
      if (item !== "undefine" && !!item) return item;
    });

    if (!tempObj[`${name}`]?.includes(value)) {
      if (tempObj[`${name}`]) {
        tempObj[name] = [...selectedCheckboxVal, value]?.join(",");
        obj[tempObj?.section_name] = [...selectedCheckboxVal, value];
        setSelectedCheckBox(obj);
      } else {
        tempObj[name] = [...selectedCheckboxVal, value]?.join(",");
        obj[tempObj?.section_name] = [...selectedCheckboxVal, value];
        setSelectedCheckBox(obj);
      }
    } else {
      tempObj[name] = selectedCheckBox[tempObj?.section_name]?.filter((item) => item !== value)?.join(",");
      obj[tempObj?.section_name] = selectedCheckBox[tempObj?.section_name]?.filter((item) => item !== value);
      setSelectedCheckBox(obj);
    }

    // obj[tempObj?.section_name] =
    //   tempObj[name]?.includes(value) &&
    //   tempObj[name]?.filter((item) => item !== value);
    setSelectedCheckBox(obj);
    tempArr[Index] = tempObj;
    setForm(tempArr);
  };

  function onFillAccessSelectUser(optionsList, selectedItem) {
    selectedFillAccessUserId += selectedItem.id + ",";
    selectedFillAccessUser.push({
      id: selectedItem.id,
      email: selectedItem.email,
    });
  }
  function onFillAccessRemoveUser(selectedList, removedItem) {
    selectedFillAccessUserId = selectedFillAccessUserId.replace(removedItem.id + ",", "");
    const index = selectedFillAccessUser.findIndex((object) => {
      return object.id === removedItem.id;
    });
    selectedFillAccessUser.splice(index, 1);
  }
  function onSignatorieselectUser(optionsList, selectedItem) {
    selectedSignatoriesUserId += selectedItem.id + ",";
    selectedSignatoriesUser.push({
      id: selectedItem.id,
      email: selectedItem.email,
    });
  }
  function onSignatoriesRemoveUser(selectedList, removedItem) {
    selectedSignatoriesUserId = selectedSignatoriesUserId.replace(removedItem.id + ",", "");
    const index = selectedFillAccessUser.findIndex((object) => {
      return object.id === removedItem.id;
    });
    selectedSignatoriesUser.splice(index, 1);
  }
  const getUserRoleAndFranchiseeData = () => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", "Bearer " + token);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(`${BASE_URL}/api/user-role`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setUserRole(res?.userRoleList);
      })
      .catch((error) => console.log("error", error));
    fetch(`${BASE_URL}/role/franchisee`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setFranchisee(res?.franchiseeList);
      })
      .catch((error) => console.log("error", error));
  };
  const setConditionField = (field, value, index, inner_index, inner_inner_index, key) => {
    counter++;
    setCount(counter);
    const tempArr = form;
    const tempObj = tempArr[index];
    const tempOption = tempObj["option"];
    if (field === "option") {
      const keyOfOption = tempOption[inner_index];
      keyOfOption[key]["option"][inner_inner_index] = { [value]: value };
      tempOption[inner_index] = keyOfOption;
      tempArr[index]["option"] = tempOption;
      setForm(tempArr);
    } else if (field === "field_type" && (value === "radio" || value === "checkbox" || value === "dropdown_selection")) {
      const keyOfOption = tempOption[inner_index];
      if (!keyOfOption[key]["option"]) {
        keyOfOption[key]["option"] = [{ "": "" }, { "": "" }];
      }

      keyOfOption[key][field] = value;
      tempOption[inner_index] = keyOfOption;
      tempArr[index]["option"] = tempOption;

      setForm(tempArr);
    } else {
      const keyOfOption = tempOption[inner_index];
      keyOfOption[key][field] = value;
      tempOption[inner_index] = keyOfOption;

      tempArr[index]["option"] = tempOption;
      setForm(tempArr);
    }
  };
  const getFormData = () => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", "Bearer " + token);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    fetch(`${BASE_URL}/form?form_name=${location?.state?.form_name}&id=${LSUserId}&role=${LSUserRole}&franchisee_id=${localStorage.getItem("franchisee_id")}&formId=${location?.state?.form_id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setFormSettingData(res?.result);
        if (res.result.franchisee) {
          selectedFranchisee = JSON.parse(res?.result?.franchisee);
        }
        if (res.result.user) {
          selectedUserRole = JSON.parse(res?.result?.user);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getFormField = () => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", "Bearer " + token);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(`${BASE_URL}/field?form_name=${form_name}&formId=${formId}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("RES:>>>>>>>>>>>>", res);
        // Fetching condition applied

        if (res?.result.length > 0) {
          if (location?.state?.update) {
            if (location?.state?.update === true) {
              if (typeof res.result !== "undefined") {
                let { result } = res;
                result.forEach((item) => {
                  if (item.field_type === "radio" || item.field_type === "checkbox" || item.field_type === "dropdown_selection") {
                    JSON.parse(item.option).forEach((d) => {
                      if (typeof Object.values(d)[0] === "object") {
                        setUpdateFlag((prevState) => ({
                          ...prevState,
                          [item.field_name]: true,
                        }));
                      } else {
                        setUpdateFlag((prevState) => ({
                          ...prevState,
                          [item.field_name]: false,
                        }));
                      }
                    });
                  }
                });
              } else {
                setUpdateFlag({});
              }
            }
          }
          let sectionData = [];
          let flag = false;
          res?.result?.map((item) => {
            if (item.field_type === "signature") {
              flag = true;
            }
            if (item.option) {
              item.option = JSON.parse(item.option);
            }
            if (item?.section_name) {
              if (!sectionData.includes(item?.section_name.split("_").join(" "))) {
                sectionData.push(item?.section_name.split("_").join(" "));
              }
            }
            if (item?.accessible_to_role === "0" || item?.accessible_to_role === false) {
              user.map((user_item) => {
                if (item?.fill_access_users) {
                  if (item?.fill_access_users.includes(user_item.id.toString())) {
                    selectedFillAccessUser.push({
                      id: user_item.id,
                      email: user_item.email,
                    });
                    selectedFillAccessUserId += user_item.id + ",";
                  }
                }
                if (item?.signatories_role) {
                  if (item?.signatories_role.includes(item.id.toString())) {
                    selectedSignatoriesUser.push({
                      id: item.id,
                      email: item.email,
                    });
                    selectedSignatoriesUserId += item.id + ",";
                  }
                }
              });
            }
          });

          setSection(sectionData);
          if (!conditionFlag && !groupFlag) {
            if (res?.form[0]?.form_permissions[0]?.signatories === true && flag === false) {
              res?.result?.push({
                field_label: `Signature ${signature_count}`,
                field_type: "signature",
              });
              signature_count++;
            }
            setForm(res?.result);

            res.result?.forEach((item) => {
              if (item?.form_field_permissions) {
                let obj = selectedCheckBox;
                let permissions = item?.form_field_permissions[0]?.fill_access_users || [];
                permissions = permissions?.filter((item) => item !== "undefine" && !!item);
                console.log("Obj (selected checkbox):::", selectedCheckBox);
                setSelectedCheckBox({
                  ...selectedCheckBox,
                  [item?.section_name]: permissions,
                });
              }
            });

            let re = res.result?.forEach((item) => {
              let permissions = item?.form_field_permissions[0]?.fill_access_users || [];
              permissions = permissions?.filter((item) => item !== "undefine" && !!item);
              return {
                ...item,
                fill_access_users: permissions?.join(","),
              };
            });

            console.log("RESULT:::", re);

            setGroupModelData(re);
            counter++;
            setCount(counter);
          } else if (groupFlag) {
            setGroupModelData(res?.result);
          }
        } else {
          if (res?.form[0]?.previous_form !== "") {
            fetch(`${BASE_URL}/field?form_name=${res?.form[0]?.previous_form}&formId=${formId}`, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                if (result?.result.length > 0) {
                  if (location?.state?.update) {
                    if (location?.state?.update === true) {
                      // setUpdateFlag(true);
                      let { result } = res;
                      result.forEach((item) => {
                        if (item.field_type === "radio" || item.field_type === "checkbox" || item.field_type === "dropdown_selection") {
                          JSON.parse(item.option).forEach((d) => {
                            if (typeof Object.values(d)[0] === "object") {
                              setUpdateFlag((prevState) => ({
                                ...prevState,
                                [item.field_name]: true,
                              }));
                            } else {
                              setUpdateFlag((prevState) => ({
                                ...prevState,
                                [item.field_name]: false,
                              }));
                            }
                          });
                        }
                      });
                    }
                  }
                  let sectionData = [];
                  let flag = false;
                  result?.result?.map((item) => {
                    if (item.field_type === "signature") {
                      flag = true;
                    }
                    delete item.id;
                    if (item.option) {
                      item.option = JSON.parse(item.option);
                    }
                    if (item?.section_name) {
                      if (!sectionData.includes(item?.section_name.split("_").join(" "))) {
                        sectionData.push(item?.section_name.split("_").join(" "));
                      }
                    }
                  });
                  setSection(sectionData);
                  if (!conditionFlag && !groupFlag) {
                    if (res?.form[0]?.form_permissions[0]?.signatories === true && flag === false) {
                      result?.result?.push({
                        field_label: `Signature ${signature_count}`,
                        field_type: "signature",
                      });
                      signature_count++;
                    }
                    setForm(result?.result);
                    setGroupModelData(result?.result);
                  } else if (groupFlag) {
                    setGroupModelData(result?.result);
                  }
                }
              });
          } else if (res?.form[0]?.form_permissions[0]?.signatories === true) {
            setForm([
              { field_type: "text" },
              { field_type: "radio", option: [{ "": "" }, { "": "" }] },
              { field_type: "checkbox", option: [{ "": "" }, { "": "" }] },
              {
                field_label: `Signature ${signature_count}`,
                field_type: "signature",
              },
            ]);
            signature_count++;
            counter++;
            setCount(counter);
          }
        }
        if (res) {
          setfullLoaderStatus(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setfullLoaderStatus(false);
      });
  };
  const deleteFormField = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", "Bearer " + token);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(`${BASE_URL}/field/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log("delete data successfully!"))
      .catch((error) => console.log("error", error));
  };
  const onSubmit = (e, form_submit_status) => {
    e.preventDefault();
    const newErrors = createFormFieldValidation(form);
    let flag = false;
    form.map((item, index) => {
      if (flag === false) {
        form.map((inner_item, inner_index) => {
          if (index !== inner_index) {
            if (item.field_label && inner_item.field_label) {
              if (item.field_label.toLowerCase() === inner_item.field_label.toLowerCase()) {
                flag = true;
              }
            }
          }
        });
      }
    });
    if (flag === true) {
      toast.error("Label name must be different.");
    } else {
      let error_flag = false;
      newErrors.map((item) => {
        if (Object.values(item)[0]) {
          if (Array.isArray(Object.values(item)[0])) {
            Object.values(item)[0].map((inner_item) => {
              if (inner_item || !inner_item === "") {
                error_flag = true;
              }
            });
          } else {
            if (!item === "" || item) {
              error_flag = true;
            }
          }
        }
      });
      if (error_flag) {
        setErrors(newErrors);
        if (newErrors.length > 0) {
          let flag = false;
          newErrors.map((item, index) => {
            if (Object.keys(item).length > 0 && !flag) {
              if (Object.keys(item)[0] === "option") {
                Object.values(item)[0].map((inner_item, inner_index) => {
                  if (Object.keys(inner_item).length > 0 && !flag) {
                    document.getElementById("option" + index + inner_index).focus();
                    flag = true;
                  }
                });
              } else {
                document.getElementById(Object.keys(item)[0] + index).focus();
                flag = true;
              }
            }
          });
        }
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", "Bearer " + token);
        let data = [...form];
        data?.map((item) => {
          data["accessible_to_role"] = item.accessible_to_role;
          data["signatories"] = item.signatories;
          if (item.form_field_permissions) {
            if (item?.form_field_permissions[0]?.accessible_to_role === "1" || item?.form_field_permissions[0]?.accessible_to_role === true) {
              console.log("if (1):::");
              item["signatories_role"] = item.signatories_role ? item.signatories_role.slice(0, -1) : null;
              item["fill_access_users"] = item?.fill_access_users || selectedCheckBox?.[item?.section_name];
            }
            if (item?.form_field_permissions[0]?.accessible_to_role === "0" || item?.form_field_permissions[0]?.accessible_to_role === false) {
              item["signatories_role"] = selectedSignatoriesUserId ? selectedSignatoriesUserId.slice(0, -1) : null;
              item["fill_access_users"] = selectedFillAccessUserId ? selectedFillAccessUserId.slice(0, -1) : null;
            }
          }

          if (item.section_name) {
            item["franchisee_id"] = localStorage.getItem("f_id");
            item["shared_by"] = LSUserId;
            item["section"] = true;
          } else {
            item["section"] = false;
          }
        });

        fetch(`${BASE_URL}/field/add?form_name=${location?.state?.form_name}&formId=${location?.state?.id}&removeFieldId=${removeConditionId}`, {
          method: "post",
          body: JSON.stringify(data),
          headers: myHeaders,
        })
          .then((res) => res.json())
          .then((res) => {
            if (form_submit_status === true) {
              navigate("/form", {
                state: {
                  message: "Form added successfully.",
                  form_template: true,
                },
              });
            } else {
              localStorage.setItem("formStatus", true);
              navigate(`/form/preview/${location?.state?.form_name}`, {
                state: {
                  id: location?.state?.id,
                  form_name: location?.state?.form_name,
                },
              });
            }

            res?.result?.map((item) => {
              if (item.option) {
                item.option = JSON.parse(item.option);
              }
            });
            setForm(res?.result);
          });
      }
    }
  };
  const setField = (field, value, index, inner_index) => {
    counter++;
    setCount(counter);
    setIndex(index);
    const tempArr = form;
    const tempObj = tempArr[index];
    if (field === "option") {
      const tempOption = tempObj["option"];
      tempOption[inner_index] = { [value]: value };
      tempArr[index]["option"] = tempOption;
      setForm(tempArr);
    } else if (field === "field_type" && (value === "radio" || value === "checkbox" || value === "dropdown_selection")) {
      tempObj["option"] = [{ "": "" }, { "": "" }];
      tempObj[field] = value;
      tempArr[index] = tempObj;
      setForm(tempArr);
    } else {
      tempObj[field] = value;
      tempArr[index] = tempObj;
      setForm(tempArr);
    }

    if (!!errors[index][field]) {
      if (field === "option") {
        let tempErrorArray = errors;
        let tempErrorObj = tempErrorArray[index];
        tempErrorObj["option"][inner_index] = undefined;
        tempErrorArray[index] = tempErrorObj;
        setErrors(tempErrorArray);
      } else {
        let tempErrorArray = errors;
        let tempErrorObj = tempErrorArray[index];
        delete tempErrorObj[field];
        tempErrorArray[index] = tempErrorObj;
        setErrors(tempErrorArray);
      }
    }
  };

  const getOneFormData = () => {
    let fieldName = [];
    let singleFieldName = [];
    var myHeaders = new Headers();
    myHeaders.append("authorization", "Bearer " + token);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const form_id = parseInt(location?.state?.id);
    const URL_ = `${BASE_URL}/form/response/own?search=&form_id=${form_id}&user_id=${LSUserId}&user_role=${LSUserRole}&from_date=&to_date=`;
    fetch(URL_, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        res?.result.map((item) => {
          item.map((inner_item) => {
            let obj = {};
            for (const key of Object.keys(eval(JSON.parse(inner_item?.fields)))) {
              if (JSON.parse(inner_item?.fields)[key] !== null) {
                obj[key] = JSON.parse(inner_item?.fields)[key];
              }
            }
            inner_item.fields = JSON.stringify(obj);
            fieldName.push(Object.keys(eval(JSON.parse(inner_item?.fields))));
            fieldName.push(Object.values(eval(JSON.parse(inner_item?.fields))));
          });
        });

        fieldName?.map((fields) => {
          fields?.map((field) => {
            if (!singleFieldName?.includes(field)) {
              singleFieldName.push(field);
            }
          });
        });

        setFormData(singleFieldName);
      })
      .catch((err) => console.log({ err }));
  };

  const getField = () => {
    let arr = [];

    form?.map((formFields, index) => {
      if (formData.includes(formFields?.field_label) || formData.includes(formFields?.field_name)) {
        arr.push(formFields?.field_label);
      }
    });

    setFieldData(arr);
  };

  console.log("Selected checkbox:::", selectedCheckBox);
  console.log("Form:::", form);
  return (
    <>
      <div id="main">
        <section className="mainsection">
          <Container>
            <div className="admin-wrapper">
              <aside className="app-sidebar">
                <LeftNavbar />
              </aside>
              <div className="sec-column">
                <TopHeader
                  selectedFranchisee={selectedFranchisee}
                  setSelectedFranchisee={(id) => {
                    id = LSUserRole === "guardian" ? localStorage.getItem("franchisee_id") : id;
                    setSelectedFranchisee(id);
                    setSelectedFranchiseeId(id);
                    localStorage.setItem("f_id", id);
                  }}
                />
                <ToastContainer />
                <FullLoader loading={fullLoaderStatus} />
                <Row>
                  <Col sm={8}>
                    <div className="mynewForm-heading">
                      <Button
                        onClick={() => {
                          navigate("/form/setting", {
                            state: {
                              id: location?.state?.id,
                              form_name: location?.state?.form_name,
                            },
                          });
                        }}
                      >
                        <img src="../../img/back-arrow.svg" />
                      </Button>
                      <h4 className="mynewForm text-capitalize">{location?.state?.form_name}</h4>
                      <Button
                        onClick={() => {
                          setFormSettingFlag(true);
                        }}
                      >
                        <img src="../../img/carbon_settings.svg" />
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Form>
                  {form?.map((item, index) => {
                    return (
                      <>
                        <div className="my-new-formsection">
                          <Row>
                            {index === 0 ? (
                              <Col sm={12}>
                                <Form.Label className="formlabel">Label {index + 1}</Form.Label>
                              </Col>
                            ) : (
                              <>
                                <Col xs={6}>
                                  <Form.Label className="formlabel">Label {index + 1}</Form.Label>
                                </Col>
                                <Col xs={6}>
                                  <div className="remove-button">
                                    <Button variant="link" onClick={() => { counter++; setCount(counter); let data = [...form]; if (data[index]?.id) { deleteFormField(data[index]?.id); } data.splice(index, 1); setForm(data); }} > <img src="../../img/removeIcon.svg" /> Remove </Button>
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                          <div className="label-one">
                            <Row>
                              <Col sm={6}>
                                <div className="my-form-input">
                                  <Form.Control
                                    type="text"
                                    name="field_label"
                                    disabled={
                                      (updateFlag[
                                        form[index]?.field_name ||
                                        form[index]["field_label"]
                                          ?.split(" ")
                                          ?.map((d) => d.charAt(0).toLowerCase() + d.slice(1))
                                          ?.join("_")
                                      ] &&
                                        newFieldAddIndex !== index) ||
                                      (item?.field_label?.includes("Signature ") && item?.field_type === "signature")
                                    }
                                    id={"field_label" + index}
                                    maxLength={255}
                                    value={form[index]?.field_label}
                                    onChange={(e) => {
                                      setFieldLabel(e.target.value);
                                      setField(e.target.name, e.target.value, index);
                                    }}
                                    placeholder="Text Label"
                                    isInvalid={!!errors[index]?.field_label}
                                  />
                                  <Form.Control.Feedback type="invalid">{errors[index]?.field_label}</Form.Control.Feedback>
                                  <div className="input-img">
                                    <img src="../../img/input-img.svg" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="text-answer-div default-arrow-select">
                                  <Form.Select
                                    name="field_type"
                                    onChange={(e) => {
                                      setField(e.target.name, e.target.value, index);
                                    }}
                                    disabled={
                                      (updateFlag[
                                        form[index]?.field_name ||
                                        form[index]["field_label"]
                                          ?.split(" ")
                                          ?.map((d) => d.charAt(0).toLowerCase() + d.slice(1))
                                          ?.join("_")
                                      ] &&
                                        newFieldAddIndex !== index) ||
                                      fieldData?.includes(form[index]?.field_label) ||
                                      (item?.field_label?.includes("Signature ") && item?.field_type === "signature")
                                    }
                                  >
                                    <option value="text_headings" selected={form[index]?.field_type === "text_headings"}>
                                      Text
                                    </option>
                                    <option value="text" selected={form[index]?.field_type === "text"}>
                                      Text Answer
                                    </option>
                                    <option value="radio" selected={form[index]?.field_type === "radio"}>
                                      Single Choice
                                    </option>
                                    <option value="checkbox" selected={form[index]?.field_type === "checkbox"}>
                                      Multiple Choice
                                    </option>
                                    <option value="date" selected={form[index]?.field_type === "date"}>
                                      Date
                                    </option>
                                    <option value="time" selected={form[index]?.field_type === "time"}>
                                      Time
                                    </option>
                                    <option value="image_upload" selected={form[index]?.field_type === "image_upload"}>
                                      Image Upload
                                    </option>
                                    <option value="document_attachment" selected={form[index]?.field_type === "document_attachment"}>
                                      Document Attachment
                                    </option>
                                    <option value="signature" selected={form[index]?.field_type === "signature"}>
                                      Signature
                                    </option>
                                    <option value="instruction_text" selected={form[index]?.field_type === "instruction_text"}>
                                      Instruction Text
                                    </option>
                                    <option value="sub_headings" selected={form[index]?.field_type === "sub_headings"}>
                                      Sub Headings
                                    </option>
                                    <option value="headings" selected={form[index]?.field_type === "headings"}>
                                      Headings
                                    </option>
                                    <option value="dropdown_selection" selected={form[index]?.field_type === "dropdown_selection"}>
                                      Drop down selection
                                    </option>
                                  </Form.Select>
                                  <div className="input-text-img">
                                    <img src={form[index]?.field_type === "text" ? "../../img/input-text-icon.svg" : form[index]?.field_type === "radio" ? "../../img/multiple-choice-icon.svg" : form[index]?.field_type === "checkbox" ? "../../img/check_boxIcon.svg" : "../../img/input-text-icon.svg"} />
                                  </div>
                                </div>
                              </Col>
                              {form[index]?.field_type === "dropdown_selection" || form[index]?.field_type === "radio" || form[index]?.field_type === "checkbox" ? (
                                <>
                                  {form[index]?.option?.map((item, inner_index) => {
                                    return (
                                      <Col sm={6}>
                                        <div className="my-form-input">
                                          <Form.Control
                                            type="text"
                                            name="option"
                                            disabled={
                                              updateFlag[
                                              form[index]?.field_name ||
                                              form[index]["field_label"]
                                                ?.split(" ")
                                                ?.map((d) => d.charAt(0).toLowerCase() + d.slice(1))
                                                ?.join("_")
                                              ] &&
                                              newFieldAddIndex !== index &&
                                              inner_index !== newOptionAddIndex
                                            }
                                            id={"option" + index + inner_index}
                                            value={Object.keys(item)[0]}
                                            onChange={(e) => {
                                              setField(e.target.name, e.target.value, index, inner_index);
                                            }}
                                            placeholder={"Option " + (inner_index + 1)}
                                            isInvalid={errors[index]?.option ? !!errors[index]?.option[inner_index] : null}
                                          />
                                          <Form.Control.Feedback type="invalid">{errors[index]?.option ? errors[index]?.option[inner_index] : ""}</Form.Control.Feedback>
                                          <div className="delete-icon">
                                            <img
                                              src="../../img/removeIcon.svg"
                                              onClick={() => {
                                                const tempArr = form;
                                                const tempObj = tempArr[index];
                                                if (tempObj["option"].length > 1) {
                                                  counter++;
                                                  setCount(counter);
                                                  tempObj["option"].splice(inner_index, 1);
                                                  tempArr[index] = tempObj;
                                                  setForm(tempArr);
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </>
                              ) : null}
                            </Row>
                          </div>

                          <div className="apply-section">
                            <Row>
                              <Col md={6}>
                                <div className="apply-condition">
                                  {form[index]?.field_type === "dropdown_selection" || form[index]?.field_type === "radio" || form[index]?.field_type === "checkbox" ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          counter++;
                                          setCount(counter);
                                          const tempArr = form;
                                          const tempObj = tempArr[index];
                                          tempObj["option"].push({ "": "" });
                                          setNewOptionAddIndex(tempObj["option"].length - 1);
                                          tempArr[index] = tempObj;
                                          setForm(tempArr);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faPlus} /> Add Option
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          let fillOptionCounter = 0;
                                          setIndex(index);
                                          const tempArr = form;
                                          const tempObj = tempArr[index];
                                          const tempOption = tempObj["option"];
                                          tempOption.map((item) => {
                                            if (!(Object.keys(item)[0] === "")) {
                                              fillOptionCounter++;
                                              if (Object.keys(item)[0].toString() === Object.values(item)[0].toString()) {
                                                setUpdateFlag((prevState) => ({
                                                  ...prevState,
                                                  [form[index]?.field_name ||
                                                    fieldLabel
                                                      ?.split(" ")
                                                      .map((d) => d.charAt(0)?.toLowerCase() + d?.slice(1))
                                                      ?.join("_")]: false,
                                                }));
                                                setChosenFieldForConditionApplied(
                                                  form[index]?.field_name ||
                                                  form[index].field_label
                                                    ?.split(" ")
                                                    ?.map((d) => d.charAt(0).toLowerCase() + d.slice(1))
                                                    ?.join("_")
                                                );
                                              }
                                            }
                                          });
                                          if (tempOption.length === fillOptionCounter) {
                                            setConditionFlag(!conditionFlag);
                                            setChosenFieldForConditionApplied(
                                              form[index]?.field_name ||
                                              form[index].field_label
                                                ?.split(" ")
                                                .map((d) => d.charAt(0)?.toLowerCase() + d.slice(1))
                                                ?.join("_")
                                            );
                                            tempOption.map((item, index) => {
                                              if (Object.keys(item)[0] === Object.values(item)[0]) {
                                                item[Object.keys(item)[0]] = {
                                                  field_type: "text",
                                                  option: [{ "": "" }, { "": "" }],
                                                };
                                              }
                                            });
                                          } else {
                                            toast.error("Please fill option first!!");
                                          }

                                          tempArr[index]["option"] = tempOption;

                                          setForm(tempArr);
                                        }}
                                      >
                                        {updateFlag[
                                          form[index]?.field_name ||
                                          form[index]["field_label"]
                                            ?.split(" ")
                                            ?.map((d) => d.charAt(0).toLowerCase() + d.slice(1))
                                            ?.join("_")
                                        ] === true
                                          ? "Condition Applied"
                                          : "Apply Condition"}
                                      </Button>
                                      <ToastContainer />
                                    </>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="add-group-t-button">
                                  <div className="add-g">
                                    <Button
                                      onClick={() => {
                                        let permissions = form[index]?.fill_access_users?.split(",");
                                        console.log("Permissions:::", permissions);
                                        setSelectedCheckBox((prevState) => ({
                                          ...prevState,
                                          [form[index]?.section_name]: permissions || selectedCheckBox[form[index]?.section_name],
                                        }));

                                        setGroupFlag(!groupFlag);
                                        setCreateSectionFlag(false);
                                        setIndex(index);
                                      }}
                                    >
                                      {item?.section_name ? (
                                        <u>
                                          <FontAwesomeIcon icon={faPen} />
                                          {"Section: " + item.section_name}
                                        </u>
                                      ) : (
                                        <>
                                          <FontAwesomeIcon icon={faPlus} /> Add to Group
                                        </>
                                      )}
                                    </Button>
                                  </div>
                                  <div className="required">
                                    <p>Required</p>
                                  </div>
                                  <div className="toogle-swich">
                                    <input
                                      className="switch"
                                      name="required"
                                      type="checkbox"
                                      checked={form[index]?.required}
                                      onChange={(e) => {
                                        setField(e.target.name, e.target.checked, index);
                                      }}
                                      disabled={form[index]?.field_type === "headings" || form[index]?.field_type === "text_headings" || form[index]?.field_type === "sub_headings"}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="add-q">
                          <Button
                            variant="link"
                            onClick={() => {
                              setNewFieldAddIndex(index + 1);
                              let data = [...form];
                              data.splice(index + 1, 0, {
                                field_type: "text",
                                field_label: "",
                              });
                              setForm(data);
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Field
                          </Button>
                        </div>
                      </>
                    );
                  })}

                  <Row>
                    <Col sm={12}>
                      <div className="button mb-5">
                        <Button className="preview" onClick={(e) => { onSubmit(e, false); }} > Preview </Button>
                        <Button className="saveForm" onClick={(e) => { onSubmit(e, true); }} > Save Form </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="applyCondition-modal">
                    {conditionFlag ? (
                      <Modal
                        show={conditionFlag}
                        onHide={() => {
                          setConditionFlag(false);
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter" className="modal-heading">
                            Condition
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="modal-condtion">
                            {form[Index]?.["option"]?.map((item, index) => {
                              return (
                                <Row>
                                  <Col sm={12}>
                                    <Form.Label className="formlabel modal-m-lable">
                                      If <span className="modal-lable">{Object.keys(item) && !(Object.keys(item)[0] === "") ? Object.keys(item)[0] : "Option " + (index + 1)} </span>
                                      is selected,
                                    </Form.Label>
                                  </Col>
                                  <Col sm={12}>
                                    <Form.Label>Label</Form.Label>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Control
                                      type="text"
                                      disabled={updateFlag[Object.values(item)[0]["field_label"]]}
                                      id={"field_label" + index}
                                      name="field_label"
                                      value={Object.values(item)[0]["field_label"]}
                                      placeholder="Some text here for the label"
                                      onChange={(e) => {
                                        setConditionField(e.target.name, e.target.value, Index, index, 0, Object.keys(item)[0]);
                                      }}
                                    />
                                  </Col>
                                  <Col lg={6} className="mt-3 mt-lg-0">
                                    <div className="text-answer-div">
                                      <Form.Select
                                        name="field_type"
                                        disabled={updateFlag[Object.values(item)[0]["field_label"]]}
                                        onChange={(e) => {
                                          setConditionField(e.target.name, e.target.value, Index, index, 0, Object.keys(item)[0]);
                                        }}
                                      >
                                        <option value="text_headings" selected={Object.values(item)[0]["field_type"] === "text_headings"}>
                                          Text
                                        </option>
                                        <option value="text" selected={Object.values(item)[0]["field_type"] === "text"}>
                                          Text Answer
                                        </option>
                                        <option value="radio" selected={Object.values(item)[0]["field_type"] === "radio"}>
                                          Single Choice
                                        </option>
                                        <option value="checkbox" selected={Object.values(item)[0]["field_type"] === "checkbox"}>
                                          Multiple Choice
                                        </option>
                                        <option value="date" selected={Object.values(item)[0]["field_type"] === "date"}>
                                          Date
                                        </option>
                                        <option value="time" selected={Object.values(item)[0]["field_type"] === "time"}>
                                          Time
                                        </option>
                                        <option value="image_upload" selected={Object.values(item)[0]["field_type"] === "image_upload"}>
                                          Image Upload
                                        </option>
                                        <option value="document_attachment" selected={Object.values(item)[0]["field_type"] === "document_attachment"}>
                                          Document Attachment
                                        </option>
                                        <option value="signature" selected={Object.values(item)[0]["field_type"] === "signature"}>
                                          Signature
                                        </option>
                                        <option value="instruction_text" selected={Object.values(item)[0]["field_type"] === "instruction_text"}>
                                          Instruction Text
                                        </option>
                                        <option value="sub_headings" selected={Object.values(item)[0]["field_type"] === "sub_headings"}>
                                          Sub Headings
                                        </option>
                                        <option value="headings" selected={Object.values(item)[0]["field_type"] === "headings"}>
                                          Headings
                                        </option>
                                        <option value="dropdown_selection" selected={Object.values(item)[0]["field_type"] === "dropdown_selection"}>
                                          Drop down selection
                                        </option>
                                      </Form.Select>
                                      <div className="input-text-img">
                                        <img src="../../img/input-text-icon.svg" />
                                      </div>
                                    </div>
                                  </Col>
                                  {item[Object.keys(item)[0]].field_type === "radio" || item[Object.keys(item)[0]].field_type === "checkbox" || item[Object.keys(item)[0]].field_type === "dropdown_selection" ? (
                                    <>
                                      {item[Object.keys(item)[0]]["option"].map((inner_item, inner_index) => {
                                        return (
                                          <Col lg={6}>
                                            <div className="my-form-input my-form-input-modal">
                                              <Form.Control
                                                type="text"
                                                name="option"
                                                disabled={
                                                  updateFlag[
                                                  item[Object.keys(item)[0]].field_label ||
                                                  form[index]["field_label"]
                                                    ?.split(" ")
                                                    ?.map((d) => d.charAt(0).toLowerCase() + d.slice(1))
                                                    ?.join("_")
                                                  ] && newConditionOptionAddIndex !== inner_index
                                                }
                                                value={inner_item[Object.keys(inner_item)[0]]}
                                                placeholder={"Option " + (inner_index + 1)}
                                                onChange={(e) => {
                                                  setConditionField(e.target.name, e.target.value, Index, index, inner_index, Object.keys(item)[0]);
                                                }}
                                              />
                                              <div
                                                className="delete-icon modal-remove-icon"
                                                onClick={() => {
                                                  const tempArr = [...form];
                                                  const tempObj = {
                                                    ...tempArr[Index],
                                                  };

                                                  const tempOption = {
                                                    ...tempObj["option"],
                                                  };

                                                  const keyOfOption = {
                                                    ...tempOption[index],
                                                  };
                                                  if (keyOfOption[Object.keys(item)[0]].option.length > 1) {
                                                    keyOfOption[Object.keys(item)[0]]["option"].splice(inner_index, 1);
                                                    setForm(tempArr);
                                                  }
                                                }}
                                              >
                                                <img src="../../img/removeIcon.svg" />
                                              </div>
                                            </div>
                                          </Col>
                                        );
                                      })}
                                    </>
                                  ) : null}

                                  <div className="apply-condition pb-2">
                                    {!(item[Object.keys(item)[0]].field_type === "text") ? (
                                      <Button
                                        onClick={() => {
                                          counter++;
                                          setCount(counter);
                                          const tempArr = form;
                                          const tempObj = tempArr[Index];
                                          const tempOption = tempObj["option"];

                                          const keyOfOption = tempOption[index];
                                          keyOfOption[Object.keys(item)[0]]["option"].push({ "": "" });
                                          setNewConditionOptionAddIndex(keyOfOption[Object.keys(item)[0]]["option"].length - 1);
                                          tempOption[index] = keyOfOption;
                                          tempArr[Index]["option"] = tempOption;
                                          setForm(tempArr);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faPlus} /> Add Option
                                      </Button>
                                    ) : null}
                                  </div>
                                </Row>
                              );
                            })}
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="back"
                            onClick={() => {
                              // getFormField();
                              removeCondition(chosenFieldForConditionApplied);
                              setUpdateFlag((prevState) => ({
                                ...prevState,
                                [chosenFieldForConditionApplied]: false,
                              }));
                              setConditionFlag(false);
                            }}
                          >
                            Remove Condtion
                          </Button>
                          <Button className="done" onClick={() => { setUpdateFlag((prevState) => ({ ...prevState, [chosenFieldForConditionApplied]: true, })); setConditionFlag(false); counter++; setCount(counter); }} > Done </Button>
                        </Modal.Footer>
                      </Modal>
                    ) : null}
                  </div>

                  <div className="select-section-modal">
                    <Modal className="select_group_model" show={groupFlag} onHide={() => { setGroupFlag(false); }} size="xl" aria-labelledby="contained-modal-title-vcenter" centered >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" className="modal-heading"> Select Section </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="modalTwo-select">
                          <div className="modal-two-check">
                            {section?.map((item) => {
                              return (
                                <>
                                  <label className="container">
                                    {item}
                                    <input
                                      type="checkbox"
                                      id={item}
                                      value={item}
                                      name="section_name"
                                      checked={form[Index]?.section_name === item.toLowerCase().split(" ").join("_")}
                                      onChange={(e) => {
                                        e.target.value = e.target.value.toLocaleLowerCase().split(" ").join("_");
                                        const tempArr = [...form];
                                        let flag = false;
                                        tempArr?.map((item, index) => {
                                          if (flag === false) {
                                            if (item.section_name) {
                                              if (item.section_name === e.target.value) {
                                                const tempObj = tempArr[Index];
                                                tempObj[e.target.name] = e.target.value;
                                                tempObj["fill_access_users"] = item.fill_access_users;
                                                tempObj["signatories_role"] = item.signatories_role;
                                                tempObj["accessible_to_role"] = item.accessible_to_role;
                                                tempObj["signatories"] = item.signatories;
                                                tempArr[Index] = tempObj;
                                                setForm(tempArr);
                                                flag = true;
                                              } else {
                                                const tempObj = tempArr[Index];
                                                tempObj[e.target.name] = e.target.value;
                                                tempObj["fill_access_users"] = "";
                                                tempObj["signatories_role"] = "";
                                                tempObj["accessible_to_role"] = "1";
                                                tempArr[Index] = tempObj;
                                                setForm(tempArr);
                                              }
                                            } else {
                                              const tempObj = tempArr[Index];
                                              tempObj[e.target.name] = e.target.value;
                                              tempObj["fill_access_users"] = "";
                                              tempObj["signatories_role"] = "";
                                              tempObj["accessible_to_role"] = "1";
                                              tempArr[Index] = tempObj;
                                              setForm(tempArr);
                                            }
                                          }
                                        });
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  {form[Index]?.section_name === item.toLowerCase().split(" ").join("_") && (
                                    <>
                                      <Col md={12}>
                                        <Form.Group>
                                          <Form.Label className="form_label_title">Select:</Form.Label>
                                          <div className="new-form-radio">
                                            <div className="new-form-radio-box">
                                              <label htmlFor="user_role">
                                                <input
                                                  type="radio"
                                                  value={1}
                                                  name="accessible_to_role"
                                                  id="user_role"
                                                  onChange={(e) => {
                                                    if (form[Index].form_field_permissions) {
                                                      let obj = selectedCheckBox;
                                                      obj[form[Index].section_name] = form[Index].form_field_permissions[0].fill_access_users || [];
                                                      setSelectedCheckBox(obj);
                                                    }
                                                    setField(e.target.name, e.target.value, Index);
                                                  }}
                                                  checked={form[Index]?.accessible_to_role === "1" || form[Index]?.accessible_to_role === true}
                                                />
                                                <span className="radio-round"></span>
                                                <p>User Roles</p>
                                              </label>
                                            </div>
                                            <div className="new-form-radio-box">
                                              <label htmlFor="specific_user">
                                                <input type="radio" value={0} name="accessible_to_role" id="specific_user"
                                                  onChange={(e) => {
                                                    setField(e.target.name, e.target.value, Index);
                                                  }}
                                                  checked={form[Index]?.accessible_to_role === "0" || form[Index]?.accessible_to_role === false}
                                                />
                                                <span className="radio-round"></span>
                                                <p>Specific Users</p>
                                              </label>
                                            </div>
                                          </div>
                                        </Form.Group>
                                      </Col>

                                      {form[Index]?.accessible_to_role === "1" || form[Index]?.accessible_to_role === true ? (
                                        <>
                                          <Col md={12} className="mt-2">
                                            <Form.Label>Fill access user:</Form.Label>
                                            <div className="checkbox-card">
                                              <div className="modal-two-check user-roles-box">
                                                <label className="container">
                                                  Franchisor Admin <input type="checkbox" name="fill_access_users" value={MonaRoles.franchisorAdmin} checked={
                                                      selectedCheckBox[form[Index]?.section_name]?.includes(MonaRoles.franchisorAdmin)
                                                      // form[
                                                      // Index
                                                      // ]?.fill_access_users?.includes(
                                                      // 'franchisor_admin'
                                                      // )
                                                    }
                                                    onChange={(e) => {
                                                      setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index);
                                                    }}
                                                  />
                                                  <span className="checkmark"></span>
                                                </label>
                                                <label className="container">
                                                  Franchise Admin
                                                  <input type="checkbox" name="fill_access_users" value="franchisee_admin" checked={
                                                      selectedCheckBox[form[Index]?.section_name]?.includes("franchisee_admin")
                                                      // form[
                                                      // Index
                                                      // ]?.fill_access_users?.includes(
                                                      // 'franchisee_admin'
                                                      // )
                                                    }
                                                    onChange={(e) => {
                                                      setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index);
                                                    }}
                                                  />
                                                  <span className="checkmark"></span>
                                                </label>
                                                <label className="container">
                                                  Coordinators
                                                  <input type="checkbox" name="fill_access_users" value="coordinator" checked={
                                                      selectedCheckBox[form[Index]?.section_name]?.includes("coordinator")
                                                      // form[
                                                      // Index
                                                      // ]?.fill_access_users?.includes(
                                                      // 'coordinator'
                                                      // )
                                                    }
                                                    onChange={(e) => {
                                                      setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index);
                                                    }}
                                                  />
                                                  <span className="checkmark"></span>
                                                </label>
                                                <label className="container">
                                                  Educators
                                                  <input type="checkbox" name="fill_access_users" value="educator" checked={
                                                      selectedCheckBox[form[Index]?.section_name]?.includes("educator")
                                                      // form[
                                                      // Index
                                                      // ]?.fill_access_users?.includes(
                                                      // 'educator'
                                                      // )
                                                    }
                                                    onChange={(e) => {
                                                      setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index);
                                                    }}
                                                  />
                                                  <span className="checkmark"></span>
                                                </label>
                                                <label className="container"> Parent/Guardian <input type="checkbox" name="fill_access_users" value="parent" checked={
                                                      selectedCheckBox[form[Index]?.section_name]?.includes("parent")
                                                      // form[
                                                      // Index
                                                      // ]?.fill_access_users?.includes(
                                                      // 'parent'
                                                      // )
                                                    }
                                                    onChange={(e) => {
                                                      setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index);
                                                    }}
                                                  />
                                                  <span className="checkmark"></span>
                                                </label>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col md={12}>
                                            <div className="sharing_section mt-3">
                                              <div className="sharing signatories-toggle">
                                                <div className="sharing-title">
                                                  <p style={{ color: "#333333", }} > Signatories </p>
                                                </div>
                                                <div className="toogle-swich">
                                                  <input className="switch" name="signatories" type="checkbox" checked={form[Index]?.signatories} onChange={(e) => { setField(e.target.name, e.target.checked, Index); }} />
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col md={12}>
                                            {form[Index]?.signatories && (
                                              <div className="checkbox-card">
                                                <div className="modal-two-check user-roles-box">
                                                  <label className="container">
                                                    Franchisor Admin
                                                    <input type="checkbox" name="signatories_role" value={MonaRoles.franchisorAdmin} checked={form[Index]?.signatories_role?.includes(MonaRoles.franchisorAdmin)} onChange={(e) => { setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index); }} />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                  <label className="container">
                                                    Franchise Admin
                                                    <input type="checkbox" name="signatories_role" value={MonaRoles.franchiseeAdmin} checked={form[Index]?.signatories_role?.includes(MonaRoles.franchiseeAdmin)} onChange={(e) => { setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index); }} />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                  <label className="container">
                                                    Coordinators
                                                    <input type="checkbox" name="signatories_role" value={MonaRoles.coordinator} checked={form[Index]?.signatories_role?.includes(MonaRoles.coordinator)} onChange={(e) => { setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index); }} />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                  <label className="container">
                                                    Educators
                                                    <input type="checkbox" name="signatories_role" value={MonaRoles.educator} checked={form[Index]?.signatories_role?.includes(MonaRoles.educator)} onChange={(e) => { setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index); }} />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                  <label className="container">
                                                    Parent/Guardian
                                                    <input type="checkbox" name="signatories_role" value={MonaRoles.parent} checked={form[Index]?.signatories_role?.includes(MonaRoles.parent)} onChange={(e) => { setCheckBoxField(form, e.target.name, e.target.value, e.target.checked, Index); }} />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                </div>
                                              </div>
                                            )}
                                          </Col>
                                        </>
                                      ) : (
                                        <>
                                          <Row style={{ marginTop: "10px" }}>
                                            <Col md={12}>
                                              <Form.Group>
                                                <Form.Label>Select Users For Fill Access</Form.Label>
                                                <div className="select-with-plus">
                                                  <Multiselect displayValue="email" className="multiselect-box default-arrow-select" selectedValues={selectedFillAccessUser} onRemove={onFillAccessRemoveUser} onSelect={onFillAccessSelectUser} options={user} />
                                                </div>
                                                <p className="error">{errors.franchisee}</p>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md={12}>
                                              <div className="sharing_section" style={{ margin: 0 }}>
                                                <div className="sharing signatories-toggle" style={{ marginTop: "10px", marginBottom: "10px", }}>
                                                  <div className="sharing-title">
                                                    <p style={{ color: "#333333", }} > Signatories </p>
                                                  </div>
                                                  <div className="toogle-swich">
                                                    <input className="switch" name="signatories" type="checkbox" checked={form[Index]?.signatories} onChange={(e) => { setField(e.target.name, e.target.checked, Index); }} />
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                          {form[Index]?.signatories && (
                                            <Col md={12}>
                                              <Form.Group>
                                                <Form.Label>Select Signatories</Form.Label>
                                                <div className="select-with-plus">
                                                  <Multiselect displayValue="email" className="multiselect-box default-arrow-select" selectedValues={selectedSignatoriesUser} onRemove={onSignatoriesRemoveUser} onSelect={onSignatorieselectUser} options={user} />
                                                </div>
                                                <p className="error">{errors.franchisee}</p>
                                              </Form.Group>
                                            </Col>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        {!createSectionFlag ? (
                          <>
                            <div className="apply-condition modal-two-footer pb-2">
                              <Button onClick={() => { setCreateSectionFlag(true); }} > <FontAwesomeIcon icon={faPlus} /> Create Section </Button>
                            </div>
                            <Button className="done" onClick={() => {
                                setGroupFlag(!groupFlag);

                                let data = [...form];
                                if (data[Index]["signatories"] === true) {
                                  let flag = false;
                                  data.map((item) => {
                                    if (item.field_type === "signature" && item.section_name === data[Index]["section_name"]) {
                                      flag = true;
                                    }
                                  });
                                  if (flag === false) {
                                    console.log("Fill access users:::", data[Index], data[Index]["fill_access_users"]);
                                    data.push({
                                      field_label: `Signature ${signature_count}`,
                                      field_type: "signature",
                                      section_name: data[Index]["section_name"],
                                      accessible_to_role: data[Index]["accessible_to_role"],
                                      fill_access_users: data[Index]["fill_access_users"],
                                      signatories: data[Index]["signatories"],
                                      signatories_role: data[Index]["signatories_role"],
                                    });
                                  }
                                  signature_count++;
                                }
                                setForm(data);
                              }}
                            >
                              Done
                            </Button>
                          </>
                        ) : null}

                        {createSectionFlag ? (
                          <div className="three-modal-footer">
                            <div className="modal-three">
                              <div className="my-form-input my-form-input-modal">
                                <Form.Control className="mb-0" type="text" name="section_title"
                                  value={sectionTitle}
                                  onChange={(e) => {
                                    setSectionTitle(e.target.value);
                                  }}
                                  placeholder="Section"
                                />
                                <Button className="right-button btn btn-danger theme-light" onClick={() => { counter++; setCount(counter); setCreateSectionFlag(false); }} > Cancel </Button>
                                <Button className="right-button" style={{ backgroundColor: "#455C58", width: "80px", }}
                                  disabled={sectionTitle === "" ? true : false}
                                  onClick={() => {
                                    counter++;
                                    setCount(counter);
                                    let sectionData = section;
                                    sectionData.push(sectionTitle);
                                    setSection(sectionData);
                                    setSectionTitle("");
                                    setCreateSectionFlag(false);
                                    let obj = selectedCheckBox;

                                    if (sectionTitle) {
                                      obj[sectionTitle.replaceAll(" ", "_")] = obj[sectionTitle.toLowerCase()] || [];
                                    }

                                    console.log("Object::::", obj);
                                    setSelectedCheckBox(obj);
                                  }}
                                >
                                  Done
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <Modal show={formSettingFlag} onHide={() => setFormSettingFlag(false)} size="lg" className="form-settings-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter" className="modal-heading">
                        <img src="../../img/carbon_settings.svg" />
                        Form Settings
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="setting_model_body">
                        <Setting onModelChange={() => { getFormField(); setFormSettingFlag(false); }} />
                      </div>
                    </Modal.Body>
                  </Modal>
                </Form>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default AddFormField;
